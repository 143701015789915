import React, { ReactNode } from "react";

import {
  StyledRegularHeadline,
  StyledSemiSmallHeadline,
  StyledSmallHeadline,
  StyleProps
} from "./Headline.styles";

type Props = {
  children: ReactNode;
  className?: string;
} & StyleProps;

export const SmallHeadline = (props: Props) => {
  return <StyledSmallHeadline {...props}>{props.children}</StyledSmallHeadline>;
};

export const SemiSmallHeadline = (props: Props) => {
  return (
    <StyledSemiSmallHeadline {...props}>
      {props.children}
    </StyledSemiSmallHeadline>
  );
};

export const RegularHeadline = (props: Props) => {
  return (
    <StyledRegularHeadline {...props}>{props.children}</StyledRegularHeadline>
  );
};
