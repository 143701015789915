import styled, { css } from 'styled-components';
import DownArrowBlack from "Assets/Icons/down-arrow-black.svg";

export const StyledHeadlineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledHeadline = styled.h1`
  margin-left: 20px;
  font-size: 20px;
  margin-bottom: 0;
  line-height: 10px;
  z-index: 100;
`;

export const StyledButton = styled.button<{ active: boolean }>`
  background-color: transparent;
  background-image: url(${DownArrowBlack});
  background-repeat: no-repeat;
  border: none;
  width: 16px;
  height: 9px;
  margin-left: 10px;
  cursor: pointer;

  -webkit-transition: all 0.2s linear;

  ${(props) => props.active && ACTIVE_BUTTON};
`;

const ACTIVE_BUTTON = css`
  -webkit-transform: scaleY(-1);
`

