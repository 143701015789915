import ThreeCoins from "Assets/stickers/3-coins.svg";
import Celebration from "Assets/stickers/celebration.svg";
import Donut from "Assets/stickers/donut.svg";
import Eye from "Assets/stickers/eye.svg";
import Now from "Assets/stickers/now.svg";
import SmallConfetti from "Assets/stickers/small-confetti.svg";
import Smiley from "Assets/stickers/smiley.svg";
import Waffle from "Assets/stickers/waffle.svg";
import WarningSigns from "Assets/stickers/warning-signs.svg";
import React from "react";

import { StyledRandomSticker } from "./RandomStickers.style";
import { StyledSticker } from "./Stickers.style";

type Props = any;

const possibleStickers = [Donut, Eye, Now, Smiley, Waffle];

export const DonutSticker = (props: Props) => {
  return <StyledSticker src={Donut} {...props} />;
};

export const EyeSticker = (props: Props) => {
  return <StyledSticker src={Eye} {...props} />;
};

export const SmileySticker = (props: Props) => {
  return <StyledSticker src={Smiley} {...props} />;
};

export const WaffleSticker = (props: Props) => {
  return <StyledSticker src={Waffle} {...props} />;
};

export const NowSticker = (props: Props) => {
  return <StyledSticker src={Now} {...props} />;
};

export const WarningSignsSticker = (props: Props) => {
  return <StyledSticker src={WarningSigns} {...props} />;
};

export const CelebrationSticker = (props: Props) => {
  return <StyledSticker src={Celebration} {...props} />;
};

export const SmallConfettiSticker = (props: Props) => {
  return <StyledSticker src={SmallConfetti} {...props} />;
};

export const ThreeCoinsSticker = (props: Props) => {
  return <StyledSticker src={ThreeCoins} {...props} />;
};

export const RandomSticker = (props: Props) => {
  const RandomSticker =
    possibleStickers[Math.floor(Math.random() * possibleStickers.length)];

  return <StyledRandomSticker src={RandomSticker} {...props} />;
};
