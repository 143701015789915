import { CompanyIdentifiers } from "company-identifiers";
import { Avatar } from "Components/Core/Avatar";
import { LargeBody } from "Components/Core/Text";
import { ContentWrapper, Wrapper } from "Components/Core/Wrapper";
import SiteConfig from "Config/Site.config";
import React from "react";
import { useHistory } from "react-router-dom";

import { OrganisationIdentifier } from "../../../generated/graphql";
import {
  StyledHeader,
  StyledImage,
  StyledSimpleCardWrapper,
} from "./OrganisationCard.styles";

type OrganisationCardProps = {
  id: string;
  name: string;
  identifiers: OrganisationIdentifier[];
  logo?: string;
};

type SimpleOrganisationCardProps = {
  id: string;
  logo: string;
  onClick?: () => void;
  name: string;
};

export const OrganisationCard = ({
  name,
  identifiers,
  logo,
  id,
}: OrganisationCardProps) => {
  const history = useHistory();
  const handleNavigation = () => history.push(id + SiteConfig.dashboardIndex);

  return (
    <ContentWrapper clickable hover onClick={handleNavigation}>
      <Wrapper center column padding={60}>
        <Avatar size={50} src={logo} shape="square" />
        <StyledHeader>{name}</StyledHeader>
        {identifiers.map(({ code: gqlCode, value }) => {
          const code = CompanyIdentifiers.GraphQLCodeToReadable(gqlCode);
          return <LargeBody>{`${code}: ` + value}</LargeBody>;
        })}
      </Wrapper>
    </ContentWrapper>
  );
};

export const SimpleOrganisationCard = ({
  logo,
  id,
  onClick,
  name,
}: SimpleOrganisationCardProps) => {
  const history = useHistory();
  const handleNavigation = () => {
    // FIXME maybe. Weird way to push new page to history?
    history.push("");
    history.replace(id + SiteConfig.dashboardIndex);
    onClick?.();
  };

  return (
    <StyledSimpleCardWrapper clickable hover onClick={handleNavigation}>
      <Wrapper column center>
        <StyledImage src={logo} />
        <LargeBody style={{ textAlign: "center" }}>{name}</LargeBody>
      </Wrapper>
    </StyledSimpleCardWrapper>
  );
};
