import React, { ReactNode } from "react";

import { StyledCard, StyleProps } from "./Card.styles";

type Props = {
  children?: ReactNode;
  className?: string;
  onClick?: () => void;
} & StyleProps;

export const Card = (props: Props) => {
  return (
    <StyledCard {...props} className={props.className}>
      {props.children}
    </StyledCard>
  );
};
