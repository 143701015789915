import { Wrapper } from "Components/Core/Wrapper";
import styled, { css } from "styled-components";

import { OrganisationDropdownList } from "../../Containers/OrganisationDropdownList";
import { Header } from "../../Core/Layout";

export const StyledHeader = styled(Header)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 25px;
  margin-bottom: 30px;
  z-index: 900;
`;

export const StyledLogoutwrapper = styled(Wrapper)`
  justify-content: flex-end;
  align-items: flex-end;
  height: 60px;
`;

export const StyledHeaderWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: flex-end;
  margin-bottom: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledHeadline = styled.h1`
  margin-left: 20px;
  font-size: 20px;
  margin-bottom: 0;
  line-height: 10px;
`;

export const StyledOrganisationDropdownList = styled(OrganisationDropdownList)`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
`;

export const StyledOverlay = styled.div<{ active: boolean }>`
  pointer-events: ${(props) => (props.active ? "default" : "none")};
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 800;

  -webkit-transition: background-color 0.2s ease-in-out;

  ${(props) => props.active && ACTIVE_OVERLAY};
`;

const ACTIVE_OVERLAY = css`
  background-color: rgba(0, 0, 0, 0.5);
`;
