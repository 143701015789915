import React from "react";

import { StyledDivider, StyleProps } from "./Divider.styles";

type Props = {
  className?: string;
} & StyleProps;

export const Divider = (props: Props) => {
  return <StyledDivider {...props} className={props.className} />;
};
