import Auth from "Library/helpers/auth";
import React, { ComponentType, useEffect, useState } from "react";
import { Redirect, Route, RouteComponentProps, RouteProps } from "react-router-dom";

import { PUBLIC_ROUTE } from "./Route.constants";

type Props = {
  component: ComponentType;
  token?: string | null;
} & RouteProps;

const PrivateRoutes = ({ component: Component, ...rest }: Props) => {
  const [isTokenValid, setIsTokenValid] = useState<boolean>(
    Auth.isTokenValid(localStorage.getItem("token"))
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setIsTokenValid(Auth.isTokenValid(localStorage.getItem("token")));
    }, 2500);
    return () => clearInterval(interval);
  }, []);

  return (
    <Route
      {...rest}
      render={(renderProps: RouteComponentProps) =>
        isTokenValid ? (
          <Component {...renderProps} />
        ) : (
          <Redirect
            to={{
              pathname: PUBLIC_ROUTE.SIGN_IN,
              state: { from: renderProps.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoutes;
