import React, { ReactNode } from "react";

import {
  StyledContentWrapper,
  StyledGridWrapper,
  StyledPageWrapper,
  StyledWrapper
} from "./Wrapper.styles";

type Props = {
  children?: ReactNode;
  center?: boolean;
  column?: boolean;
  spaceBetween?: boolean;
  marginTop?: number;
  marginRight?: number;
  padding?: number;
  paddingTop?: number;
  hover?: boolean;
  clickable?: boolean;
  width?: string;
  height?: string;
  flex?: number;
  onClick?: () => void;
  style?: React.CSSProperties;
};

export const Wrapper = React.forwardRef((props: Props, ref: React.Ref<any>) => {
  return (
    <StyledWrapper ref={ref} {...props}>
      {props.children}
    </StyledWrapper>
  );
});

export const ContentWrapper = (props: Props) => {
  return (
    <StyledContentWrapper {...props}>{props.children}</StyledContentWrapper>
  );
};

export const PageWrapper = (props: Props) => {
  return <StyledPageWrapper {...props}>{props.children}</StyledPageWrapper>;
};

export const GridWrapper = (props: Props) => {
  return <StyledGridWrapper {...props}>{props.children}</StyledGridWrapper>;
};
