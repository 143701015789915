import React from "react";

import {
  StyledBody,
  StyledErrorText,
  StyledHeadline,
  StyledLabel,
  StyledLargeBody,
  StyledSmallBalginHeadline,
  StyledSmallHeadline,
  StyledWarningText
} from "./Text.styles";

type Props = any;

export const StyledLargeHeadline = ({ className, children, style }: Props) => {
  return (
    <StyledHeadline style={style} className={className}>
      {children}
    </StyledHeadline>
  );
};

export const Headline = ({ className, children, style }: Props) => {
  return (
    <StyledHeadline style={style} className={className}>
      {children}
    </StyledHeadline>
  );
};

export const LargeBody = ({ className, children, style }: Props) => {
  return (
    <StyledLargeBody style={style} className={className}>
      {children}
    </StyledLargeBody>
  );
};

export const SmallHeadline = ({ className, children, style }: Props) => {
  return (
    <StyledSmallHeadline style={style} className={className}>
      {children}
    </StyledSmallHeadline>
  );
};

export const SmallBalginHeadline = (props: Props) => {
  return (
    <StyledSmallBalginHeadline {...props}>
      {props.children}
    </StyledSmallBalginHeadline>
  );
};

export const Body = ({ className, children, style }: Props) => {
  return (
    <StyledBody style={style} className={className}>
      {children}
    </StyledBody>
  );
};

export const ErrorText = ({ className, children, style }: Props) => {
  return (
    <StyledErrorText style={style} className={className}>
      {children}
    </StyledErrorText>
  );
};

export const WarningText = ({ className, children, style }: Props) => {
  return (
    <StyledWarningText style={style} className={className}>
      {children}
    </StyledWarningText>
  );
};

export const Label = ({ className, children, style }: Props) => {
  return (
    <StyledLabel style={style} className={className}>
      {children}
    </StyledLabel>
  );
};
