import React from "react";

import {
  StyledAddIcon,
  StyledArrowIcon,
  StyledDeleteIcon,
  StyledDownload,
  StyledDownloadWhite,
  StyledEdit,
  StyledExport,
  StyledHelpIcon,
  StyledIntegration,
  StyledIntegrationLink,
  StyledIntegrationSelection1,
  StyledIntegrationSelection2,
  StyledIntegrationSelection3,
  StyledPlusIcon,
  StyledRetry,
  StyledSettings,
  StyledSetupIntegration,
  StyledTaskList
} from "./Icon.styles";
import { IconEyeOpen } from "./IconEyeOpen";

type Props = any;

export type IconProps = {
  color: string;
};

export const PlusIcon = (props: Props) => {
  return <StyledPlusIcon {...props} />;
};

export const DeleteIcon = (props: Props) => {
  return <StyledDeleteIcon {...props} />;
};

export const EditIcon = (props: Props) => {
  return <StyledEdit {...props} />;
};

export const ArrowIcon = (props: Props) => {
  return <StyledArrowIcon {...props} />;
};

export const HelpIcon = (props: Props) => {
  return <StyledHelpIcon {...props} />;
};

export const IntegrationSelection1 = (props: Props) => {
  return <StyledIntegrationSelection1 {...props} />;
};

export const IntegrationSelection2 = (props: Props) => {
  return <StyledIntegrationSelection2 {...props} />;
};

export const IntegrationSelection3 = (props: Props) => {
  return <StyledIntegrationSelection3 {...props} />;
};

export const Integration = (props: Props) => {
  return <StyledIntegration {...props} />;
};

export const IntegrationLink = (props: Props) => {
  return <StyledIntegrationLink {...props} />;
};

export const AddIcon = (props: Props) => {
  return <StyledAddIcon {...props} />;
};

export const SettingsIcon = (props: Props) => {
  return <StyledSettings {...props} />;
};

export const IntegrationIcon = (props: Props) => {
  return <StyledIntegration {...props} />;
};

export const SetupIntegration = (props: Props) => {
  return <StyledSetupIntegration {...props} />;
};

export const ExportIcon = (props: Props) => {
  return <StyledExport {...props} />;
};

export const TaskList = (props: Props) => {
  return <StyledTaskList {...props} />;
};

export const Download = (props: Props) => {
  return <StyledDownload {...props} />;
};

export const Retry = (props: Props) => {
  return <StyledRetry {...props} />;
};

export const DownloadWhite = (props: Props) => {
  return <StyledDownloadWhite {...props} />;
};

export { IconEyeOpen };
