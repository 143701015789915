import "antd/dist/antd.css";

import {
  BOX_SHADOW,
  COLORS,
  TRANSITION_DURATION
} from "Shared/Constants/Style.constants";
import styled, { css } from "styled-components";

export const StyledContentWrapper = styled.div<{
  center?: boolean;
  column?: boolean;
  marginTop?: number;
  marginRight?: number;
  padding?: number;
  hover?: boolean;
  clickable?: boolean;
  width?: string;
  height?: string;
  flex?: number;
  style?: any;
}>`
  display: flex;
  padding: 50px;
  width: ${(props) => props?.width};
  height: ${(props) => props?.height};
  flex: ${(props) => props?.flex};

  background: ${COLORS.CONTENT};
  border-radius: 5px;
  ${(props) => props?.column && COLUMN};
  ${(props) => props?.center && CENTER};
  ${BOX_SHADOW};
  ${TRANSITION_DURATION};
  margin-top: ${(props) => props?.marginTop}px;
  margin-right: ${(props) => props?.marginRight}px;
`;

export const StyledPageWrapper = styled.div<{
  center?: boolean;
  column?: boolean;
  marginTop?: number;
  padding?: number;
  paddingTop?: number;
  flex?: number;
  style?: any;
}>`
  display: flex;
  flex: ${(props) => props?.flex};
  padding: ${(props) => (props?.padding ? props.padding : "48")}px;
  padding-top: ${(props) => (props?.paddingTop ? props.paddingTop : "25")}px;
  border-radius: 5px;
  ${(props) => props?.column && COLUMN};
  ${(props) => props?.center && CENTER};
  margin-top: ${(props) => props?.marginTop}px;
`;

export const StyledWrapper = styled.div<{
  center?: boolean;
  column?: boolean;
  spaceBetween?: boolean;
  marginTop?: number;
  marginRight?: number;
  padding?: number;
  height?: string;
  flex?: number;
  width?: string;
  style?: any;
}>`
  display: flex;
  flex: ${(props) => props?.flex};
  border-radius: 5px;
  width: ${(props) => (props?.width ? props.width : "100%")};
  height: ${(props) => props?.height};
  padding: ${(props) => props?.padding}px;
  ${(props) => props?.column && COLUMN};
  ${(props) => props?.center && CENTER};
  ${(props) => props?.spaceBetween && SPACE_BETWEEN};
  margin-right: ${(props) => props?.marginRight}px;
  margin-top: ${(props) => props?.marginTop}px;
`;

export const StyledGridWrapper = styled.div<{
  marginTop?: number;
  columns?: number;
  style?: any;
}>`
  display: grid;
  grid-template-columns: repeat(
    ${(props) => (props?.columns ? props.columns : "3")},
    1fr
  );
  grid-template-rows: repeat(1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin-top: ${(props) => props?.marginTop}px;
`;

const CENTER = css`
  justify-content: center;
  align-items: center;
`;

const COLUMN = css`
  flex-direction: column;
`;

const SPACE_BETWEEN = css`
  justify-content: space-between;
`;
