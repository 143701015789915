import { AbilitiesPerUserRole, AbilityGraphQl } from "generated/graphql";
import { LOCALSTORAGE_KEY_USER_ACCESS_ABILITIES } from "Library/Firebase/Firebase.authentication";

export const hasAuthAccess = (
  orgId: string,
  ability: AbilityGraphQl
): boolean => {
  const savedAccessFromLogin = localStorage.getItem(
    LOCALSTORAGE_KEY_USER_ACCESS_ABILITIES
  );

  if (!savedAccessFromLogin) {
    // No access found for org, let's give access anyway for retro-compatibility
    // (API should handle what to give to the user anyway)
    // TODO Consider logging out the user
    console.warn(
      `[userAccess] User has no saved access info on ${LOCALSTORAGE_KEY_USER_ACCESS_ABILITIES}`
    );
    return true;
  }

  const savedAccess: AbilitiesPerUserRole[] = JSON.parse(savedAccessFromLogin);

  const accessForOrg = savedAccess.filter((s) => s.orgId === orgId);
  if (accessForOrg.length === 0) {
    // No access found for org.
    // TODO Consider logging out the user
    console.warn(`[userAccess] User has no saved access for org ${orgId}`);
    return false;
  }

  const hasAuthAccessResult = accessForOrg.some((access) =>
    access?.abilities?.includes(ability)
  );

  if (!hasAuthAccessResult) {
    console.warn(
      `[userAccess] User has access for org ${orgId}, but not the required ability ${ability}`
    );
  }

  return hasAuthAccessResult;
};
