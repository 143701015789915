import styled from "styled-components";
import { Avatar } from "antd";

const StyledAvatar = styled(Avatar)<{ background: string }>`
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
  background-color: ${(props) => props?.background};
`;

export default StyledAvatar;
