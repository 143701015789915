import { Dropdown } from "antd";
import { Avatar } from "Components/Core/Avatar";
import { Menu, MenuItem } from "Components/Core/Menu";
import { Label } from "Components/Core/Text";
import React from "react";
import { Link } from "react-router-dom";
import { COLORS } from "Shared/Constants/Style.constants";

type Props = {
  name: string;
  logout: () => void;
};

export const LogoutForm = ({ name, logout }: Props) => {
  const menu = (
    <Menu>
      <MenuItem>
        <Link onClick={logout} to={"/"}>
          Log Out
        </Link>
      </MenuItem>
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <Avatar
        style={{ background: "rgba(222, 64, 159, 0.3)" }}
        shape="circle"
        size={46}
      >
        <Label style={{ color: COLORS.PURPLE }}>{name[0]}</Label>
      </Avatar>
    </Dropdown>
  );
};
