import React from "react";
import {
  StyledLayout,
  StyledSider,
  StyledHeader,
  StyledContent,
  StyledFooter,
} from "./Layout.styles";

type Props = any;

export const Layout = (props: Props) => {
  return <StyledLayout {...props} />;
};

export const Sider = (props: Props) => {
  return <StyledSider {...props} />;
};

export const Header = (props: Props) => {
  return <StyledHeader {...props} />;
};

export const Content = (props: Props) => {
  return <StyledContent {...props} />;
};

export const Footer = (props: Props) => {
  return <StyledFooter {...props} />;
};
