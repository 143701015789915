import React from "react";

import { RegularHeadline } from "../Headline";
import { StyledAvatar } from "./Avatar.styles";

type Props = {
  initial: string;
  backgroundColor?: string;
  labelColor?: string;
};

export const Avatar = (props: Props) => {
  return (
    <StyledAvatar {...props}>
      <RegularHeadline color={props.labelColor}>
        {props.initial}
      </RegularHeadline>
    </StyledAvatar>
  );
};
