import { Body, Label } from "Components/Core/Text";
import { Wrapper } from "Components/Core/Wrapper";
import { GraphQLError } from "graphql";
import { ButterGraphQLException } from "GraphQL/types";
import React from "react";
import { toast } from "react-toastify";

import {
  StyledContainer,
  StyledEyeSticker,
  StyledTextWrapper,
} from "./Toast.styles";

type Props = {
  message?: {
    label: string;
    body: string;
  };
  key?: number | string;
};

type SmartErrorProps = {
  t: any;
  error: any;
};

export const ToastContainer = () => {
  return <StyledContainer />;
};

export const ToastSuccess = ({ message, key }: Props) => {
  toast.success(
    <Wrapper>
      <StyledEyeSticker />
      <StyledTextWrapper>
        <Label>{message?.label}</Label>
        <Body>{message?.body}</Body>
      </StyledTextWrapper>
    </Wrapper>,
    { toastId: key }
  );
};

export const ToastError = ({ message, key }: Props) => {
  toast.error(
    <Wrapper>
      <StyledTextWrapper>
        <Label>{message?.label}</Label>
        <Body>{message?.body}</Body>
      </StyledTextWrapper>
    </Wrapper>,
    { toastId: key }
  );
};

export const ToastSmartError = ({ t, error }: SmartErrorProps) => {
  if (error?.graphQLErrors) {
    error?.graphQLErrors?.forEach((error: GraphQLError) => {
      const exception = error?.extensions?.exception as ButterGraphQLException;

      // This might happen with Firebase errors relayed
      const exceptionCode = exception.code;

      // Those come from StatusCodeException({message: "Error message", errorCode: "X", code: "Y"}) types of errors
      const response = exception.response;

      ToastError({
        message: t([
          `errorMessages:${
            response?.errorCode ?? response?.code ?? exceptionCode
          }`,
          "errorMessages:UNSPECIFIC",
        ]),
      });
    });
  } else {
    ToastError({
      message: t([`errorMessages:${error.code}`, "errorMessages:UNSPECIFIC"]),
    });
  }
};
