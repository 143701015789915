import jwtDecode from "jwt-decode";
import * as Sentry from "@sentry/react";

class Auth {
  isTokenValid = (token) => {
    if (!token) {
      return false;
    }
    try {
      const profile = jwtDecode(token);

      const expiredAt = profile.expiredAt || profile.exp * 1000;

      if (expiredAt > new Date().getTime()) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      Sentry.captureException(e);
      return false;
    }
  };
}
export default new Auth();
