import Logo from "Components/Core/Logo";
import SiteConfig from "Config/Site.config";
import React, { ReactElement } from "react";
import { NavLink } from "react-router-dom";

import { OrganisationHeader } from "../OrganisationHeader";
import {
  StyledHeader,
  StyledHeaderWrapper,
  StyledLogoutwrapper,
  StyledOrganisationDropdownList,
  StyledOverlay
} from "./Header.styles";

type Props = {
  children?: ReactElement;
  orgName?: string;
  shouldHideDropDown?: boolean;
};

export const Header = ({ children, orgName, shouldHideDropDown }: Props) => {
  const [activeDropdown, setActiveDropdown] = React.useState<boolean>(false);

  return (
    <>
      <StyledHeader style={{ marginTop: "25px" }}>
        <StyledHeaderWrapper>
          {
            <NavLink to={"/organisations"}>
              <Logo src={SiteConfig.siteLogo} width={"140px"} />
            </NavLink>
          }
          {orgName && (
            <OrganisationHeader
              orgName={orgName}
              active={activeDropdown}
              onClickDropdown={() => setActiveDropdown(!activeDropdown)}
            />
          )}
        </StyledHeaderWrapper>

        <StyledLogoutwrapper>{children}</StyledLogoutwrapper>
        {!shouldHideDropDown && (
          <StyledOrganisationDropdownList
            active={activeDropdown}
            onSelect={() => setActiveDropdown(false)}
          />
        )}
      </StyledHeader>
      <StyledOverlay active={activeDropdown} />
    </>
  );
};
