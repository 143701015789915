import { COLORS, SMALL_SPACING } from "Shared/Constants/Style.constants";
import styled, { css } from "styled-components";

/**
 * StyledCard contains base styling, should be very generic.
 *
 * To extend to specific card-types, please add a property to StyleProps
 * create a new style case like "RoundedEdgesStyling" and add it to the StyledCard
 * component.
 *
 * Please try to create very specific style cases, avoid overlapping
 * styles with other cases. It's better to have very specific cases and combine
 * them.
 *
 * E.g. <Card roundedEdges dropShadow></Card>
 */

export type StyleProps = {
  roundedEdges?: boolean;
};

const RoundedEdgesStyling = css`
  border-radius: 10px;
`;

export const StyledCard = styled.div<StyleProps>`
  background-color: white;
  border: 0.33px solid ${COLORS.LIGHT_GREY};
  padding: ${SMALL_SPACING};

  ${(props) => props.roundedEdges && RoundedEdgesStyling};
`;
