import "react-toastify/dist/ReactToastify.css";

import { EyeSticker } from "Components/Core/Stickers";
import { ToastContainer } from "react-toastify";
import { COLORS } from "Shared/Constants/Style.constants";
import styled from "styled-components";

export const StyledContainer = styled(ToastContainer).attrs({
  closeButton: false,
  hideProgressBar: true,
  position: "bottom-right",
  closeOnClick: true,
})`
  .Toastify__toast-container {
  }
  .Toastify__toast {
    border-radius: 8.17121px;
    height: 70px;
  }
  .Toastify__toast--error {
    background-color: ${COLORS.ERROR};
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--success {
    background-color: ${COLORS.SUCCESS};
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;

export const StyledEyeSticker = styled(EyeSticker)`
  width: 50px;
  height: 50px;
`;

export const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
`;
