import React from "react";

import {
  StyledAddUserRoyalBlue,
  StyledBlackDownArrow,
  StyledBlueSmiley,
  StyledBurgerMenuIcon,
  StyledCheckIcon,
  StyledCrossIcon,
  StyledGreyLocationPinIcon,
  StyledMenuCloseIcon,
  StyledMultipleUserRoyalBlueIcon,
  StyledNotifications,
  StyledSellerUserIcon,
  StyledShiftSellerArrow,
  StyledShiftTakerArrow,
  StyledSwapShiftIcon,
  StyledToast,
  StyledUserGreyIcon,
  StyledUserRoyalBlueBigIcon,
  StyledUserRoyalBlueIcon,
  StyledUserTurquoiseIcon,
  StyledWave,
  StyledWhiteSelectArrow
} from "./Icon.styles";

type Props = {
  onClick?: () => void;
  className?: string;
  color?: string;
};

export const CheckIcon = (props: Props) => {
  return <StyledCheckIcon {...props} />;
};

export const CrossIcon = (props: Props) => {
  return <StyledCrossIcon {...props} />;
};

export const GreyLocationPinIcon = (props: Props) => {
  return <StyledGreyLocationPinIcon {...props} />;
};

export const UserRoyalBlueIcon = (props: Props) => {
  return <StyledUserRoyalBlueIcon {...props} />;
};

export const MultipleUserRoyalBlueIcon = (props: Props) => {
  return <StyledMultipleUserRoyalBlueIcon {...props} />;
};

export const UserRoyalBlueBigIcon = (props: Props) => {
  return <StyledUserRoyalBlueBigIcon {...props} />;
};

export const UserTurquoiseIcon = (props: Props) => {
  return <StyledUserTurquoiseIcon {...props} />;
};

export const AddUserRoyalBlueIcon = (props: Props) => {
  return <StyledAddUserRoyalBlue {...props} />;
};

export const BurgerMenuIcon = (props: Props) => {
  return <StyledBurgerMenuIcon {...props} />;
};

export const MenuCloseIcon = (props: Props) => {
  return <StyledMenuCloseIcon {...props} />;
};

export const BlackDownArrow = (props: Props) => {
  return <StyledBlackDownArrow {...props} />;
};

export const WhiteSelectArrow = (props: Props) => {
  return <StyledWhiteSelectArrow {...props} />;
};

export const UserGreyIcon = (props: Props) => {
  return <StyledUserGreyIcon {...props} />;
};

export const BlueSmileyIcon = (props: Props) => {
  return <StyledBlueSmiley {...props} />;
};

export const ShiftSellerArrow = (props: Props) => {
  return <StyledShiftSellerArrow {...props} />;
};

export const ShiftTakerArrow = (props: Props) => {
  return <StyledShiftTakerArrow {...props} />;
};

export const SellerUserIcon = (props: Props) => {
  return <StyledSellerUserIcon {...props} />;
};

export const SwapShiftIcon = (props: Props) => {
  return <StyledSwapShiftIcon {...props} />;
};

export const Toast = (props: Props) => {
  return <StyledToast {...props} />;
};

export const Notifcations = (props: Props) => {
  return <StyledNotifications {...props} />;
};
export const Wave = (props: Props) => {
  return <StyledWave {...props} />;
};
