import { COLORS } from "Shared/Constants/Style.constants";
import styled, { css } from "styled-components";

export type StyleProps = {
  horizontal?: boolean;
  vertical?: boolean;
};

const HorizontalDividerStyles = css`
  display: flex;
  flex-direction: row;
  flex: 1;
  border-top: 0.33px solid ${COLORS.LIGHT_GREY};
`;

const VerticalDividerStyles = css`
  display: flex;
  flex-direction: column;

  border-left: 0.33px solid ${COLORS.LIGHT_GREY};
`;

export const StyledDivider = styled.div<StyleProps>`
  ${(props) => props.horizontal && HorizontalDividerStyles};
  ${(props) => props.vertical && VerticalDividerStyles};
`;
