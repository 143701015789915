import React from 'react';
import { StyledHeadlineWrapper, StyledHeadline, StyledButton } from './OrganisationHeader.styles';

type Props = {
  orgName: string;
  active: boolean;
  onClickDropdown: () => void;
}

export const OrganisationHeader = ({ orgName, active, onClickDropdown }: Props) => {

  return (
    <StyledHeadlineWrapper>
      <StyledHeadline>for {orgName}</StyledHeadline>
      <StyledButton active={active} onClick={onClickDropdown}/>
    </StyledHeadlineWrapper>
  );
}