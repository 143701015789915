import {
  COLORS,
  TEXT_BODY_HINT,
  TEXT_BODY_LARGE,
  TEXT_BODY_REGULAR,
  TEXT_BODY_SMALL
} from "Shared/Constants/Style.constants";
import styled from "styled-components";

export type StyleProps = {
  color?: string;
};

export const StyledHintBodyText = styled.p<StyleProps>`
  ${TEXT_BODY_HINT}
  color: ${(props) => (props.color ? props.color : COLORS.BLACK)};
  margin: 0;
  padding: 0;
`;

export const StyledSmallBodyText = styled.p<StyleProps>`
  ${TEXT_BODY_SMALL}
  color: ${(props) => (props.color ? props.color : COLORS.BLACK)};
  margin: 0;
  padding: 0;
`;

export const StyledRegularBodyText = styled.p<StyleProps>`
  ${TEXT_BODY_REGULAR}
  color: ${(props) => (props.color ? props.color : COLORS.BLACK)};
  margin: 0;
  padding: 0;
`;

export const StyledLargeBodyText = styled.p<StyleProps>`
  ${TEXT_BODY_LARGE}
  color: ${(props) => (props.color ? props.color : COLORS.BLACK)};
  margin: 0;
  padding: 0;
`;
