import { MenuProps } from "antd/lib/menu";
import { MenuItemProps } from "antd/lib/menu/MenuItem";
import React from "react";

import { StyledMenu, StyledMenuItem } from "./Menu.styles";

export const Menu = (props: MenuProps) => {
  return <StyledMenu {...props} />;
};

export const MenuItem = (props: MenuItemProps) => {
  return <StyledMenuItem {...props} />;
};
