import {
  COLORS,
  HEADLINE_REGULAR,
  HEADLINE_SEMI_SMALL,
  HEADLINE_SMALL
} from "Shared/Constants/Style.constants";
import styled from "styled-components";

export type StyleProps = {
  color?: string;
};

export const StyledRegularHeadline = styled.h2<{ color?: string }>`
  ${HEADLINE_REGULAR};
  margin: 0;
  padding: 0;
  color: ${(props) => (props.color ? props.color : COLORS.BLACK)};
`;

export const StyledSemiSmallHeadline = styled.h3<{ color?: string }>`
  ${HEADLINE_SEMI_SMALL};
  margin: 0;
  padding: 0;
  color: ${(props) => (props.color ? props.color : COLORS.BLACK)};
`;

export const StyledSmallHeadline = styled.h4<{ color?: string }>`
  ${HEADLINE_SMALL}
  margin: 0;
  padding: 0;
  color: ${(props) => (props.color ? props.color : COLORS.BLACK)};
`;
