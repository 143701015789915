import { useCallback, useEffect, useState } from "react";

export enum QueryType {
  MAX_WIDTH,
  MIN_WIDTH,
}

/**
 * Might not be the ideal solution for handling conditonally
 * rendering content based on screen size. But it will work for now.
 */

export const useMediaQuery = (queryType: QueryType, value: number) => {
  const getMediaState = useCallback(() => {
    if (queryType === QueryType.MAX_WIDTH) {
      return window.innerWidth <= value;
    } else if (queryType === QueryType.MIN_WIDTH) {
      return window.innerWidth >= value;
    } else {
      return false;
    }
  }, [queryType, value]);

  let initialState = getMediaState();

  const [mediaQuery, setMediaQuery] = useState<boolean>(initialState);

  useEffect(() => {
    const updateMedia = () => {
      setMediaQuery(getMediaState());
    };

    window.addEventListener("resize", updateMedia);
    return () => {
      window.removeEventListener("resize", updateMedia);
    };
  }, [getMediaState]);

  return mediaQuery;
};
