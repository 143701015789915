import { Menu, MenuItem } from "Components/Core/Menu";
import { Divider } from "Components/CoreV2/Divider";
import { MenuCloseIcon } from "Components/CoreV2/Icon";
import { RegularBodyText } from "Components/CoreV2/Text";
import { LargeBodyText } from "Components/CoreV2/Text/Text";
import { Wrapper } from "Components/CoreV2/Wrapper";
import {
  COLORS,
  SMALL_SPACING,
  XXX_SMALL_SPACING,
} from "Shared/Constants/Style.constants";
import styled, { css } from "styled-components";

import { Export } from "@styled-icons/entypo/Export";
import { Users } from "@styled-icons/fa-solid/Users";
import { Settings } from "@styled-icons/ionicons-sharp/Settings";
import { Code } from "@styled-icons/zondicons/Code";

export const StyledTextMenu = styled(Menu)`
  display: flex;

  .ant-menu-item {
    ::hover {
      border-bottom: none !important;
    }

    ::after {
      border-bottom: none !important;
    }
  }

  .ant-menu-item-selected a {
    color: ${COLORS.PURPLE};
    border-bottom: none !important;

    :after {
      content: "";

      width: 40px;
      position: absolute;
      left: 0;
      top: 20px;
      right: 0;
      margin: auto;

      border-width: none;
    }
  }

  .ant-menu-item a:hover {
    color: ${COLORS.PURPLE};
    border-bottom: none !important;
  }
`;

export const StyledIconMenu = styled(Menu)`
  display: flex;

  .ant-menu-item span {
    margin-top: 4px;
  }

  .ant-menu-item a:hover {
    color: ${COLORS.PURPLE};
  }

  .ant-menu-item-selected a {
    color: ${COLORS.PURPLE};
  }

  .ant-menu-item-selected {
    margin: 0;
    svg {
      fill: ${COLORS.PURPLE};
    }
  }

  .ant-menu-item-icon {
    margin: 0;
  }
  .ant-menu-item {
    padding: 0;
    margin-right: 32px;
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: flex-end;
    align-items: center;
    svg {
      transition: fill 0.3s;
    }
    :hover {
      svg {
        fill: ${COLORS.PURPLE};
        transition: fill 0.3s;
      }
    }
  }

  .span {
    line-height: 14px;
  }

  margin-left: 100px;
`;

export const StyledMobileMenu = styled(Menu)`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: flex-end;
  border-radius: 10px 0 0 10px;
  align-items: flex-start;
  line-height: 16px;
  position: relative;
  overflow: hidden;
  overflow-y: scroll;
  height: 100%;

  .ant-menu-item {
    :hover {
      opacity: 0.6 !important;
    }
  }

  .ant-menu-item-selected {
    :after {
      border: none;
    }

    svg {
      /* Changes the color of the svg to pink. Might not work on older browsers
       so it might be necessary to create a pink svg replacement in the future. */
      filter: invert(60%) sepia(89%) saturate(5807%) hue-rotate(301deg)
        brightness(93%) contrast(86%);
    }

    a {
      p {
        color: ${COLORS.PURPLE};
      }
    }
  }
`;

export const StyledEmptySpaceLeftOfMenu = styled(Wrapper)`
  flex: 1;
`;

export const StyledOuterWrapper = styled(Wrapper)`
  background: white;
  width: 85%;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  padding: 0 24px;
  height: 100%;
  border-radius: 15px 0 0 15px;
`;

export const StyledHeaderWrapper = styled(Wrapper)`
  display: flex;
  margin: ${SMALL_SPACING} 0;
  flex-direction: column;
  justify-content: flex-end;
`;

export const StyledMenuCloseIcon = styled(MenuCloseIcon)`
  align-self: flex-end;
  cursor: pointer;
`;

export const StyledMobileMenuItem = styled(MenuItem)`
  width: auto !important;
  border: none;
  padding: 0 !important;
  margin: 0 !important;
`;

export const StyledLargeBodyText = styled(LargeBodyText)`
  font-weight: bold;
`;

export const StyledRegularBodyText = styled(RegularBodyText)`
  font-weight: bold;
`;

export const StyledDivider = styled(Divider)`
  width: 100%;
  flex: 0;
  margin: ${XXX_SMALL_SPACING} 0;
  padding-right: -24px;

  @media screen and (min-width: 321px) {
    margin: ${SMALL_SPACING} 0;
  }
`;
export const StyledIconMobileMenuItem = styled(MenuItem)`
  margin: 0;
  padding: 0px !important;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 14px !important;
  display: flex;
  align-items: center;
`;

export const StyledMobileLogOut = styled(MenuItem)`
  margin: 0 !important;
  padding: 0 !important;

  a {
    color: ${COLORS.PURPLE};
    font-weight: bold;
  }
`;

export const StyledIconMenuItem = styled(MenuItem)`
  margin: 0;
  padding: 0px;
  font-family: WorkSans;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 14px !important;
  .a:hover {
    color: ${COLORS.PURPLE};
  }
`;

export const StyledTextMenuItem = styled(MenuItem)`
  padding-left: 0;
  font-family: WorkSans;
  font-size: 21px;
  font-weight: 700;
  text-transform: capitalize;

  a:hover {
    color: ${COLORS.PURPLE};
  }

  .ant-menu-item-selected::after {
    border-bottom: none !important;
  }

  .ant-menu-item {
    border-bottom: none !important;
  }
`;

export const StyledMenuWrapper = styled.div`
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledMobileMenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: rgba(0, 0, 0, 0.3);

  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
`;

export const NavigationWrapper = styled(Wrapper)`
  flex: 1;
`;

export const ICON = css`
  width: 15px;
  height: 15px;
`;

export const StyledUsers = styled(Users)`
  ${ICON}
`;

export const StyledIntegrations = styled(Code)`
  ${ICON}
`;

export const StyledSettings = styled(Settings)`
  ${ICON}
`;

export const StyledExport = styled(Export)`
  ${ICON}
`;
