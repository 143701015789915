import { List } from "antd";
import { OrganisationCard } from "Components/CombinedV2/OrganisationCard";
import { SmallBodyText } from "Components/CoreV2/Text";
import { Wrapper } from "Components/CoreV2/Wrapper";
import { UserContext } from "Context/UserProvider";
import { useGetAdminOrganisationsQuery } from "generated/graphql";
import React, { useContext, useState } from "react";
import { COLORS } from "Shared/Constants/Style.constants";

import {
  StyledBlackDownArrow,
  StyledList,
  StyledListWrapper,
  StyledRegularBodyText,
  StyledWrapper
} from "./SelectOrganisationContainer.styles";

type Props = {
  activeCompanyName: string;
};

export const SelectOrganisationContainer = ({ activeCompanyName }: Props) => {
  const { userId } = useContext(UserContext);
  const [dropdownActive, setDropdownActive] = useState<boolean>(false);

  const { data, loading, error } = useGetAdminOrganisationsQuery({
    variables: { id: userId },
    skip: userId ? false : true,
  });
  if (loading) return <></>;

  if (error) {
    return <div>error...</div>;
  }

  const roles = data?.user?.roles!;
  const rolesWithOrg = roles.filter((r) => r?.organisation?._id);

  const foundOrgsIds: string[] = [];
  const rolesUniqueByOrgId = rolesWithOrg.filter((r) => {
    if (foundOrgsIds.includes(r?.organisation?._id?.toString() ?? "no-id")) {
      return false;
    } else {
      foundOrgsIds.push(r?.organisation?._id?.toString() ?? "no-id");
      return true;
    }
  });

  const renderItem = (item: any) => {
    const { _id, logo, name } = item?.organisation;

    return (
      <List.Item key={_id}>
        <OrganisationCard logo={logo} orgId={_id} orgName={name} />
      </List.Item>
    );
  };

  return (
    <StyledWrapper active={dropdownActive}>
      <Wrapper horizontalAlignment>
        <StyledRegularBodyText>{`for ${activeCompanyName}`}</StyledRegularBodyText>
        <StyledBlackDownArrow
          active={dropdownActive}
          onClick={() => setDropdownActive((prevState) => !prevState)}
        />
      </Wrapper>
      <StyledListWrapper>
        {dropdownActive && (
          <>
            <SmallBodyText color={COLORS.DARK_GREY}>
              Select your organisation
            </SmallBodyText>
            <StyledList
              dataSource={rolesUniqueByOrgId}
              renderItem={renderItem}
              itemLayout={"horizontal"}
              grid={{ gutter: 27 }}
            />
          </>
        )}
      </StyledListWrapper>
    </StyledWrapper>
  );
};
