import { Card } from "Components/CoreV2/Card";
import { SmallHeadline } from "Components/CoreV2/Headline";
import { X_TINY_SPACING, XX_SMALL_SPACING } from "Shared/Constants/Style.constants";
import styled from "styled-components";

export const StyledListCard = styled(Card)`
  padding: 0;
  box-shadow: 1px 1px 13px rgba(0, 0, 0, 0.25);
  margin-right: ${XX_SMALL_SPACING};
  cursor: pointer;
  display: flex;
  flex: 1;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${X_TINY_SPACING};
`;

export const StyledImage = styled.img`
  display: block;
  object-fit: contain;
  margin-bottom: 10px;
  width: 50px;
  height: 50px;
`;

export const StyledSmallHeadline = styled(SmallHeadline)`
display: -webkit-box;
max-width: 200px;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
overflow: hidden;
text-align:center;
}
`;
