import { ToastContainer } from "Components/Combined/Toast";
import i18n from "Config/Translations/I18n";
import LogRocket from "logrocket";
import React from "react";
import { I18nextProvider } from "react-i18next";
import GlobalStyles from "Shared/Constants/globalStyles";

import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/react";

import client from "./Apollo/Client";
import { SegmentProvider } from "./Context/SegmentProvider/SegmentProvider";
import { UserProvider } from "./Context/UserProvider/UserProvider";
import Routes from "./Routes";

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  LogRocket.init("butter/butterweb-xno6q");
}

Sentry.init({
  environment: process.env.REACT_APP_ENVIRONMENT,
  dsn: "https://c8794f616eb740529d3e59f6433f0f08@o468865.ingest.sentry.io/5497346",
  ignoreErrors: [
    // The "Non-Error" error messages are caused by throwing a non-error object.
    // In some MSFT browsers (like embedded Outlook), those errors seem to pop up a lot,
    // in a recursive way, consuming a lot of the Sentry's quota.
    "Non-Error exception captured",
    "Non-Error promise rejection captured",
  ],
});

function App() {
  return (
    <Sentry.ErrorBoundary fallback={"An error has occurred"}>
      <ApolloProvider client={client}>
        <GlobalStyles />
        <I18nextProvider i18n={i18n}>
          <SegmentProvider>
            <UserProvider>
              <Routes />
            </UserProvider>
          </SegmentProvider>
          <ToastContainer />
        </I18nextProvider>
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
