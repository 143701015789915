import { GetUserAccessAbilitiesDocument } from "generated/graphql";

import apolloClient from "../../Apollo/Client";
import firebase, { auth } from "./Firebase";

export const DEPRECATED_LOCALSTORAGE_KEY_USER_ACCESS_ABILITIES = `userAccessAbilities`;

export const LOCALSTORAGE_KEY_USER_ACCESS_ABILITIES = `userAccessAbilities-${process.env.REACT_APP_ENVIRONMENT}`;
export const LOCALSTORAGE_KEY_USER_ID = `userId-${process.env.REACT_APP_ENVIRONMENT}`;


export const replaceTokenLocalStorage = async (
  token: string | null | undefined
): Promise<void> => {
  localStorage.removeItem("token");

  if (token) {
    localStorage.setItem("token", token);
  }

  await updateLocalUserAccessAbilities();
};

export const signInWithEmail = async (
  email: string,
  password: string
): Promise<{
  firebaseAuthResult: firebase.auth.UserCredential;
}> => {
  const firebaseAuthResult = await auth().signInWithEmailAndPassword(
    email,
    password
  );
  const token = await firebaseAuthResult?.user?.getIdToken();

  // Replace token for subsequent requests
  localStorage.removeItem("token");
  localStorage.setItem("token", token!);

  await updateLocalUserAccessAbilities();

  return {
    firebaseAuthResult,
  };
};

export const updateLocalUserAccessAbilities = async () => {
  const { data } = await apolloClient.query({
    query: GetUserAccessAbilitiesDocument,
    variables: {},
    fetchPolicy: "no-cache",
  });

  localStorage.setItem(
    LOCALSTORAGE_KEY_USER_ACCESS_ABILITIES,
    JSON.stringify(data?.getUserAccessAbilities?.abilitiesPerUserRole)
  );
};

export const createUserWithEmailAndPassword = async (
  email: string,
  password: string
) => await auth().createUserWithEmailAndPassword(email, password);

export const deleteUser = async (user: firebase.User) => await user.delete();

export const signout = async () => await auth().signOut();
