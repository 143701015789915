import logo from "Assets/Images/Logo/logotype_normal.svg";
import logoShort from "Assets/Images/Logo/g.svg";

export default {
  siteName: "All Gravy",
  siteLogo: logo,
  siteShortLogo: logoShort,
  indexRoute: "/organisations",
  dashboardIndex: "/employees",
  shiftRequestsIndex: "/shiftrequests",
};
