import { Header } from "Components/Combined/Header";
import Navigation, { MobileNavigation } from "Components/Combined/Navigation";
import { Logout } from "Components/Containers/Logout";
import { Content, Layout } from "Components/Core/Layout/Layout";
import { BurgerMenuIcon } from "Components/CoreV2/Icon";
import {
  AbilityGraphQl,
  useGetOrganisationGeneralSettingsInformationQuery
} from "generated/graphql";
import { hasAuthAccess } from "Library/helpers/authAccess";
import React, { ComponentType } from "react";
import { useParams } from "react-router-dom";
import { MEDIUM_SPACING } from "Shared/Constants/Style.constants";
import { QueryType, useMediaQuery } from "Shared/Hooks/useMediaQuery";

export const withDashboard =
  <P extends object>(BaseComponent: ComponentType) =>
  (props: P) => {
    let { orgId } = useParams<{ orgId: string }>();

    let orgName;
    if (orgId) {
      const { data } = useGetOrganisationGeneralSettingsInformationQuery({
        variables: { id: orgId },
      });

      orgName = data?.Organisation?.name;
    }

    const authAccessData = {
      canReadDashboard: hasAuthAccess(orgId, AbilityGraphQl.DashboardRead),
      canReadEmployees: hasAuthAccess(orgId, AbilityGraphQl.StaffRead),
      canReadIntegrations: hasAuthAccess(
        orgId,
        AbilityGraphQl.IntegrationsRead
      ),
      canReadTaskList: hasAuthAccess(orgId, AbilityGraphQl.TaskListRead),
      canReadOrgSettings: hasAuthAccess(orgId, AbilityGraphQl.OrgSettingsRead),
      canReadShiftRequests: hasAuthAccess(
        orgId,
        AbilityGraphQl.ShiftRequestsRead
      ),
    };

    const [showMobileMenu, setShowMobileMenu] = React.useState<boolean>(false);

    const isMobileOrTablet = useMediaQuery(QueryType.MAX_WIDTH, 1224);

    const toggleMobileMenu = () => {
      setShowMobileMenu((prevState: boolean) => !prevState);
    };

    if (isMobileOrTablet) {
      return (
        <>
          {showMobileMenu && (
            <MobileNavigation
              toggleMobileMenu={toggleMobileMenu}
              orgName={orgName}
              orgId={orgId}
              authAccessData={authAccessData}
            />
          )}
          <Layout
            style={{
              height: "auto",
              position: "relative",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                padding: `${MEDIUM_SPACING} ${MEDIUM_SPACING} 0 ${MEDIUM_SPACING}`,
              }}
            >
              {() => toggleMobileMenu()}
              <div style={{ cursor: "pointer" }}>
                <BurgerMenuIcon onClick={() => toggleMobileMenu()} />
              </div>
            </div>

            <Content>
              <BaseComponent {...props} />
            </Content>
          </Layout>
        </>
      );
    }

    return (
      <Layout>
        <Header orgName={orgName}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Navigation authAccessData={authAccessData} />

            <Logout />
          </div>
        </Header>

        <Content>
          <BaseComponent {...props} />
        </Content>
      </Layout>
    );
  };
