import React, { ComponentType, lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  RouteComponentProps,
  Switch
} from "react-router-dom";

import { withDashboard } from "../HOC/Dashboard";
import PrivateRoutes from "./PrivateRoutes";
import { PRIVATE_ROUTE, PUBLIC_ROUTE } from "./Route.constants";

const Signin = lazy(
  (): Promise<{ default: ComponentType<any> }> => import("../Pages/Signin")
);
const Signup = lazy(
  (): Promise<{ default: ComponentType<any> }> => import("../Pages/Signup")
);
const TinkCallback = lazy(
  (): Promise<{ default: ComponentType<any> }> =>
    import("../Pages/TinkCallback")
);
const RegistrationSuccess = lazy(
  (): Promise<{ default: ComponentType<any> }> =>
    import("../Pages/RegistrationSuccess")
);
const TermsAndConditions = lazy(
  (): Promise<{ default: ComponentType<any> }> =>
    import("../Pages/TermsAndConditions")
);
const VerifyRegistrationV2 = lazy(
  (): Promise<{ default: ComponentType<any> }> =>
    import("../Pages/VerifyRegistrationV2")
);

const VerifyRegistrationAdmin = lazy(
  (): Promise<{ default: ComponentType<any> }> =>
    import("../Pages/VerifyRegistrationAdmin")
);

const Employees = withDashboard(
  lazy(
    (): Promise<{ default: ComponentType<any> }> =>
      import("../Pages/Employer/Employees")
  )
);
const Subscriptions = withDashboard(
  lazy(
    (): Promise<{ default: ComponentType<any> }> =>
      import("../Pages/Employer/Subscriptions")
  )
);
const Integrations = withDashboard(
  lazy(
    (): Promise<{ default: ComponentType<any> }> =>
      import("../Pages/Employer/Integrations")
  )
);

const OrganisationChecks = withDashboard(
  lazy(
    (): Promise<{ default: ComponentType<any> }> =>
      import("../Pages/Employer/OrganisationChecks")
  )
);

const Settings = withDashboard(
  lazy(
    (): Promise<{ default: ComponentType<any> }> =>
      import("../Pages/Employer/Settings")
  )
);
const Export = withDashboard(
  lazy(
    (): Promise<{ default: ComponentType<any> }> =>
      import("../Pages/Employer/Export")
  )
);

const ShiftApproval = withDashboard(
  lazy(
    (): Promise<{ default: ComponentType<any> }> =>
      import("../Pages/Employer/ShiftApproval")
  )
);

const NotFound404 = lazy(
  (): Promise<{ default: ComponentType<any> }> => import("../Pages/NotFound404")
);

const Organisations = lazy(
  (): Promise<{ default: ComponentType<any> }> =>
    import("../Pages/Employer/Organisations")
);
const ZenergyCallback = lazy(
  (): Promise<{ default: ComponentType<any> }> =>
    import("../Pages/Employer/ZenergyCallback")
);
const DanloenCallback = lazy(
  (): Promise<{ default: ComponentType<any> }> =>
    import("../Pages/Employer/DanloenCallback")
);

const Dashboard = withDashboard(
  lazy(
    (): Promise<{ default: ComponentType<any> }> =>
      import("../Pages/Employer/Dashboard")
  )
);

const SavingsCalculator = lazy(
  (): Promise<{ default: ComponentType<any> }> =>
    import("../Pages/SavingsCalculator")
);

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: Signin,
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    exact: true,
    component: Signin,
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    exact: true,
    component: Signup,
  },
  {
    path: PUBLIC_ROUTE.TINK_CALLBACK,
    exact: true,
    component: TinkCallback,
  },
  {
    path: PUBLIC_ROUTE.VERIFY_REGISTRATION_ADMIN,
    exact: true,
    component: VerifyRegistrationAdmin,
  },
  {
    path: PUBLIC_ROUTE.VERIFY_REGISTRATION_V2,
    exact: true,
    component: VerifyRegistrationV2,
  },
  {
    path: PUBLIC_ROUTE.SUCCESS_REGISTRATION,
    exact: true,
    component: RegistrationSuccess,
  },
  {
    path: PUBLIC_ROUTE.TERMS_AND_CONDITIONS,
    exact: true,
    component: TermsAndConditions,
  },
  {
    path: PUBLIC_ROUTE.SAVINGS_CALCULATOR,
    exact: true,
    component: SavingsCalculator,
  },
];

const privateRoutes = [
  {
    path: PRIVATE_ROUTE.EMPLOYEES,
    exact: true,
    component: Employees,
  },
  {
    path: PRIVATE_ROUTE.SUBSCRIPTIONS,
    exact: true,
    component: Subscriptions,
  },
  {
    path: PRIVATE_ROUTE.INTEGRATIONS,
    exact: true,
    component: Integrations,
  },
  {
    path: PRIVATE_ROUTE.SETTINGS,
    exact: true,
    component: Settings,
  },
  {
    path: PRIVATE_ROUTE.EXPORT,
    exact: true,
    component: Export,
  },
  {
    path: PRIVATE_ROUTE.ORGANISATIONS,
    exact: true,
    component: Organisations,
  },
  {
    path: PRIVATE_ROUTE.ORGANISATION_CHECKS,
    exact: true,
    component: OrganisationChecks,
  },
  {
    path: PRIVATE_ROUTE.DASHBOARD,
    exact: true,
    component: Dashboard,
  },
  {
    path: PRIVATE_ROUTE.SHIFT_REQUESTS,
    exact: true,
    component: ShiftApproval,
  },
  {
    path: PRIVATE_ROUTE.ZENERGY_CALLBACK,
    exact: true,
    component: ZenergyCallback,
  },
  {
    path: PRIVATE_ROUTE.DANLOEN_CALLBACK,
    exact: true,
    component: DanloenCallback,
  },
];

const Routes = () => {
  return (
    <Suspense fallback={<React.Fragment />}>
      <Router>
        <Switch>
          {publicRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              render={(renderProps: RouteComponentProps) => {
                return <route.component {...renderProps} />;
              }}
            />
          ))}
          {privateRoutes.map((route, index) => (
            <PrivateRoutes
              key={index}
              path={route.path}
              exact={route.exact}
              component={route.component}
            />
          ))}
          <Route path="*">
            <NotFound404 />
          </Route>
        </Switch>
      </Router>
    </Suspense>
  );
};

export default Routes;
