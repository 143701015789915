import { List } from "antd";
import { UserContext } from "Context/UserProvider";
import { useGetAdminOrganisationsQuery } from "generated/graphql";
import React, { useContext } from "react";

import * as Sentry from "@sentry/react";

import {
  StyledCard,
  StyledListWrapper,
  StyledText
} from "./OrganisationDropdownList.styles";

type Props = {
  active: boolean;
  onSelect?: () => void;
  className?: string;
};

export const OrganisationDropdownList = ({
  active,
  onSelect,
  className,
}: Props) => {
  const { userId } = useContext(UserContext);

  const { data, loading, error } = useGetAdminOrganisationsQuery({
    variables: { id: userId },
    skip: userId ? false : true,
  });
  if (loading) return <></>;

  if (error) {
    Sentry.captureException(error);
    return <div>error...</div>;
  }

  const roles = data?.user?.roles!;

  const renderItem = (item: any) => {
    const { _id, logo, name } = item.organisation!;

    return (
      <List.Item>
        <StyledCard
          key={_id}
          id={_id}
          logo={logo}
          onClick={onSelect}
          name={name}
        />
      </List.Item>
    );
  };

  return (
    <StyledListWrapper className={className} active={active}>
      <StyledText>Select your organisation</StyledText>
      <List
        dataSource={roles}
        renderItem={renderItem}
        itemLayout={"horizontal"}
        grid={{ gutter: 27 }}
      />
    </StyledListWrapper>
  );
};
