import { Avatar } from "antd";
import { COLORS } from "Shared/Constants/Style.constants";
import styled from "styled-components";

export const StyledAvatar = styled(Avatar)<{
  backgroundColor?: string;
}>`
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : COLORS.LIGHT_GREY};
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-avatar-string {
    transform: none !important;
    position: static;
    left: 0;
  }
`;
