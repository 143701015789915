import React from "react";

import StyledLogo from "./Logo.styles";

type Props = {
  src: string;
  size?: string;
  width?: string;
};

const Logo = (props: Props) => {
  return <StyledLogo {...props} />;
};

export default Logo;
