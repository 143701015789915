import { ContentWrapper } from "Components/Core/Wrapper";
import styled from "styled-components";

export const StyledHeader = styled.h1`
  margin-top: 25px;
`;

export const StyledSimpleCardWrapper = styled(ContentWrapper)`
  border-radius: 16px;
  width: 400px;
  justify-content: center;
  align-items: center;
  margin-right: 27px;
`;

export const StyledImage = styled.img`
  width: 50px;
  height: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
`;
