import { COLORS } from "Shared/Constants/Style.constants";
import styled from "styled-components";

export const StyledLargeHeadline = styled.h1<{ textAlign: string }>`
  font-family: BalginLightSmExpanded;
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
`;

export const StyledHeadline = styled.h1`
  font-family: BalginLightSmExpanded;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
`;

export const StyledLargeBody = styled.p`
  font-family: WorkSans;
  font-style: normal;
  font-weight: normal;
  font-size: 17.9291px;
  line-height: 27px;
  color: ${COLORS.DARK_GREY};
`;

export const StyledSmallBalginHeadline = styled.h3<{ textAlign: string }>`
  font-family: BalginLightSmExpanded;
  font-style: normal;
  font-weight 1000;
  font-size: 12px;
  text-align: ${(props) => props?.textAlign};
  color: ${COLORS.BLACK};
`;

export const StyledSmallHeadline = styled.h1`
  font-family: WorkSans;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: ${COLORS.DARK_GREY};
`;

export const StyledBody = styled.p`
  font-family: WorkSans;
  font-style: normal;
  font-weight: normal;
  font-size: 10.4591px;
  line-height: 12px;
  color: ${COLORS.WHITE};
`;

export const StyledErrorText = styled.p`
  font-family: WorkSans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: ${COLORS.BLUE};
`;

export const StyledWarningText = styled.p`
  font-family: WorkSans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: ${COLORS.GREY};
`;

export const StyledLabel = styled.label`
  font-family: BalginExpanded;
  font-style: normal;
  font-weight: 600;
  font-size: 13.0739px;
  line-height: 18px;
  color: ${COLORS.WHITE};
`;
