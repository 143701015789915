import "antd/dist/antd.css";

import { Layout } from "antd";
import { COLORS } from "Shared/Constants/Style.constants";
import styled, { css } from "styled-components";

const { Sider, Content, Header, Footer } = Layout;

export const StyledLayout = styled(Layout)<{ center?: boolean }>`
  display: flex;
  ${(props) => props?.center && CENTER};
  height: 100vh;
  background-color: ${COLORS.BACKGROUND};
`;

export const StyledSider = styled(Sider)``;

export const StyledContent = styled(Content)`
  background-color: ${COLORS.BACKGROUND};
`;

export const StyledHeader = styled(Header)``;

export const StyledFooter = styled(Footer)``;

const CENTER = css`
  justify-content: center;
  align-items: center;
`;
