import { IntegrationIcon, SettingsIcon, TaskList } from "Components/Core/Icon";
import React from "react";

export type NavigationAuthAccessData = {
  canReadDashboard: boolean;
  canReadEmployees: boolean;
  canReadIntegrations: boolean;
  canReadTaskList: boolean;
  canReadOrgSettings: boolean;
};

export enum NavigationOptionKeys {
  DASHBOARD = "dashboard",
  EMPLOYEES = "employees",
  INTEGRATIONS = "integrations",
  TASKS = "tasks",
  SETTINGS = "settings",
  SHIFT_REQUESTS = "shiftRequests",
}

export type NavigationOption = {
  key: NavigationOptionKeys;
  label: string;
  leftIcon?: JSX.Element;
};

export const headerTextOptions: NavigationOption[] = [
  {
    key: NavigationOptionKeys.DASHBOARD,
    label: "dashboard",
  },
  {
    key: NavigationOptionKeys.EMPLOYEES,
    label: "staff",
  },
  {
    key: NavigationOptionKeys.SHIFT_REQUESTS,
    label: "shiftRequests",
  },
];

export const headerIconOptions: NavigationOption[] = [
  {
    key: NavigationOptionKeys.INTEGRATIONS,
    label: "integrations",
    leftIcon: <IntegrationIcon />,
  },
  {
    key: NavigationOptionKeys.TASKS,
    label: "taskList",
    leftIcon: <TaskList />,
  },
  {
    key: NavigationOptionKeys.SETTINGS,
    label: "settings",
    leftIcon: <SettingsIcon />,
  },
];
