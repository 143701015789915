import { Menu } from "antd";
import MenuItem from "antd/lib/menu/MenuItem";
import styled from "styled-components";
import { COLORS } from "Shared/Constants/Style.constants";

export const StyledMenu = styled(Menu)`
  background-color: ${COLORS.BACKGROUND};
  border: none !important;
  display: flex;
  align-items: center;
  .ant-menu-item-selected {
    color: ${COLORS.PURPLE} !important;
    background-color: transparent !important;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  background-color: ${COLORS.BACKGROUND};
  line-height: 0px;

  border-bottom: 0px !important;

  :hover {
    color: ${COLORS.PURPLE} !important;
  }
`;
