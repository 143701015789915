import { StyledRegularBodyText } from "Components/CoreV2/Text/Text.styles";
import SiteConfig from "Config/Site.config";
import { Role } from "generated/graphql";
import React from "react";
import { useHistory } from "react-router-dom";

import {
  StyledImage,
  StyledListCard,
  StyledSmallHeadline
} from "./OrganisationCard.styles";

type Props = {
  logo?: string;
  orgId: string;
  orgName: string;
  className?: string;
  role?: Role;
  flag?: string;
};

export const OrganisationCard = (props: Props) => {
  const history = useHistory();

  const handleNavigation = () => {
    // FIXME maybe. Weird way to push new page to history?
    history.push("");

    if (props.role && props.role === Role.StaffManager) {
      history.replace(props.orgId + SiteConfig.shiftRequestsIndex);
    } else {
      history.replace(props.orgId + SiteConfig.dashboardIndex);
    }
  };

  return (
    <StyledListCard
      className={props?.className}
      roundedEdges
      onClick={() => handleNavigation()}
    >
      <StyledImage src={props.logo} />
      <StyledRegularBodyText>{props?.flag}</StyledRegularBodyText>
      <StyledSmallHeadline>{props.orgName}</StyledSmallHeadline>
    </StyledListCard>
  );
};
