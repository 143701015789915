import React, { ReactNode } from "react";

import {
  StyledHintBodyText,
  StyledLargeBodyText,
  StyledRegularBodyText,
  StyledSmallBodyText,
  StyleProps
} from "./Text.styles";

type Props = {
  children?: ReactNode;
  className?: string;
} & StyleProps;

export const HintBodyText = (props: Props) => {
  return <StyledHintBodyText {...props}>{props.children}</StyledHintBodyText>;
};

export const SmallBodyText = (props: Props) => {
  return <StyledSmallBodyText {...props}>{props.children}</StyledSmallBodyText>;
};

export const RegularBodyText = (props: Props) => {
  return (
    <StyledRegularBodyText {...props}>{props.children}</StyledRegularBodyText>
  );
};

export const LargeBodyText = (props: Props) => {
  return <StyledLargeBodyText {...props}>{props.children}</StyledLargeBodyText>;
};
