import React, { createContext, ReactElement, useEffect, useState } from "react";

import { Analytics, AnalyticsBrowser } from "@segment/analytics-next";

type Props = {
  children: ReactElement;
};

export const SegmentContext = createContext<Analytics>({} as Analytics);

export const SegmentProvider = ({ children }: Props) => {
  const [analytics, setAnalytics] = useState<Analytics>({} as Analytics);
  const [writeKey, setWriteKey] = useState<string>(
    process.env.REACT_APP_SEGMENT_WRITE_KEY ?? ""
  );

  useEffect(() => {
    const environment = process.env.REACT_APP_ENVIRONMENT ?? "";
    const segmentWriteKey = process.env.REACT_APP_SEGMENT_WRITE_KEY ?? "";

    setWriteKey(segmentWriteKey);

    const loadAnalytics = async () => {
      let [response] = await AnalyticsBrowser.load({ writeKey });
      setAnalytics(response);
    };

    if (environment === "production") loadAnalytics();
  }, [writeKey]);

  return (
    <SegmentContext.Provider value={analytics}>
      {children}
    </SegmentContext.Provider>
  );
};
