import { ReactComponent as AddUserRoyalBlue } from "Assets/Icons/add-user-royal-blue.svg";
import { ReactComponent as Check } from "Assets/Icons/approve-icon.svg";
import { ReactComponent as BlueSmiley } from "Assets/Icons/blue-smiley.svg";
import { ReactComponent as Cross } from "Assets/Icons/decline-icon.svg";
import { ReactComponent as BlackDownArrow } from "Assets/Icons/down-arrow-black.svg";
import { ReactComponent as LocationPin } from "Assets/Icons/location-grey.svg";
import { ReactComponent as BurgerMenu } from "Assets/Icons/menu-burger.svg";
import { ReactComponent as MenuClose } from "Assets/Icons/menu-close.svg";
import { ReactComponent as MultipleUserRoyalBlue } from "Assets/Icons/multiple-user-royal-blue.svg";
import { ReactComponent as Notifications } from "Assets/Icons/notifications.svg";
import { ReactComponent as SellerUser } from "Assets/Icons/seller-user-icon.svg";
import { ReactComponent as ShiftSellerArrow } from "Assets/Icons/shift-seller-arrow.svg";
import { ReactComponent as ShiftTakerArrow } from "Assets/Icons/shift-taker-arrow.svg";
import { ReactComponent as SwapShiftIcon } from "Assets/Icons/swap-shift-icon.svg";
import { ReactComponent as Toast } from "Assets/Icons/toast.svg";
import { ReactComponent as UserGrey } from "Assets/Icons/user-grey.svg";
import { ReactComponent as UserRoyalBlueBig } from "Assets/Icons/user-royal-blue-big.svg";
import { ReactComponent as UserRoyalBlue } from "Assets/Icons/user-royal-blue.svg";
import { ReactComponent as UserTurquoise } from "Assets/Icons/user-turquoise.svg";
import { ReactComponent as Wave } from "Assets/Icons/wave.svg";
import { ReactComponent as WhiteSelectArrow } from "Assets/Icons/white-select-arrow.svg";
import styled from "styled-components";

export const StyledCheckIcon = styled(Check)`
  display: block;
  width: 25px;
  height: 25px;
`;

export const StyledCrossIcon = styled(Cross)`
  display: block;
  width: 25px;
  height: 25px;
`;

export const StyledGreyLocationPinIcon = styled(LocationPin)`
  display: block;
  width: 25px;
  height: 25px;
`;

export const StyledUserRoyalBlueIcon = styled(UserRoyalBlue)`
  display: block;
  width: 25px;
  height: 25px;
`;

export const StyledMultipleUserRoyalBlueIcon = styled(MultipleUserRoyalBlue)`
  display: block;
  width: 25px;
  height: 25px;
`;

export const StyledUserRoyalBlueBigIcon = styled(UserRoyalBlueBig)`
  display: block;
  width: 25px;
  height: 25px;
`;

export const StyledUserTurquoiseIcon = styled(UserTurquoise)`
  display: block;
  width: 25px;
  height: 25px;
`;

export const StyledWave = styled(Wave)`
  display: block;
  width: 25px;
  height: 25px;
`;
export const StyledNotifications = styled(Notifications)`
  display: block;
  width: 25px;
  height: 25px;
`;

export const StyledToast = styled(Toast)`
  display: block;
  width: 25px;
  height: 25px;
  color: white;
  fill: white;
`;

export const StyledAddUserRoyalBlue = styled(AddUserRoyalBlue)`
  display: block;
  width: 25px;
  height: 25px;
`;

export const StyledBurgerMenuIcon = styled(BurgerMenu)`
  display: block;
  width: 25px;
  height: 25px;
`;

export const StyledMenuCloseIcon = styled(MenuClose)`
  display: block;
  width: 25px;
  height: 25px;
`;

export const StyledBlackDownArrow = styled(BlackDownArrow)`
  display: block;
  width: 25px;
  height: 25px;
`;

export const StyledWhiteSelectArrow = styled(WhiteSelectArrow)`
  display: block;
  width: 25px;
  height: 25px;
`;

export const StyledUserGreyIcon = styled(UserGrey)`
  display: block;
  width: 25px;
  height: 25px;
`;

export const StyledBlueSmiley = styled(BlueSmiley)`
  display: block;
  width: 25px;
  height: 25px;
`;

export const StyledShiftSellerArrow = styled(ShiftSellerArrow)`
  display: block;
  width: 25px;
  height: 25px;
`;

export const StyledShiftTakerArrow = styled(ShiftTakerArrow)`
  display: block;
  width: 25px;
  height: 25px;
`;

export const StyledSellerUserIcon = styled(SellerUser)`
  display: block;
  width: 25px;
  height: 25px;
`;

export const StyledSwapShiftIcon = styled(SwapShiftIcon)`
  display: block;
  width: 25px;
  height: 25px;
`;
