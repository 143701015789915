import { css } from "styled-components";

import { IndexMap } from "./constants";

// HEADLINE
export const HEADLINE_INPUT_SMALL = "12px";

// TEXT

export const TEXT_FONT_FAMILY = "WorkSans";
export const TEXT_LARGE_BODY = "18px";
export const TEXT_PLACEHOLDER = "16px";
export const TEXT_INPUT_BODY = "16px";

// REFACTORED TEXT SIZES

export enum TextType {
  HINT_BODY,
  SMALL_BODY,
  REGULAR_BODY,
  LARGE_BODY,
}

export const TEXT_BODY_HINT = css`
  font-family: WorkSans;
  font-size: 10px; // Smallest mobile first size ( width: 320px )
`;

export const TEXT_BODY_SMALL = css`
  font-family: WorkSans;
  font-size: 10px; // Smallest mobile first size ( width: 320px )

  // All other devices ( until more breakpoints are added )
  @media screen and (min-width: 321px) {
    font-size: 12px;
  }
`;

export const TEXT_BODY_REGULAR = css`
  font-family: WorkSans;
  font-size: 12px; // Smallest mobile first size ( width: 320px )

  // All other devices ( until more breakpoints are added )
  @media screen and (min-width: 321px) {
    font-size: 16px;
  }
`;

export const TEXT_BODY_LARGE = css`
  font-family: WorkSans;
  font-size: 16px; // Smallest mobile first size ( width: 320px )

  // All other devices ( until more breakpoints are added )
  @media screen and (min-width: 321px) {
    font-size: 21px;
  }
`;

// REFACTORED HEADLINE

export const HEADLINE_SMALL = css`
  font-family: Balgin;
  font-size: 10px; // Smallest mobile first size ( width: 320px )

  // All other devices ( until more breakpoints are added )
  @media screen and (min-width: 321px) {
    font-size: 12px;
  }
`;

export const HEADLINE_SEMI_SMALL = css`
  font-family: Balgin;
  font-size: 12px; // Smallest mobile first size ( width: 320px )

  // All other devices ( until more breakpoints are added )
  @media screen and (min-width: 321px) {
    font-size: 16px;
  }
`;

export const HEADLINE_REGULAR = css`
  font-family: Balgin;
  font-size: 21px; // Smallest mobile first size ( width: 320px )

  // All other devices ( until more breakpoints are added )
  @media screen and (min-width: 321px) {
    font-size: 28px;
  }
`;

export const HEADLINE_LARGE = css`
  font-family: Balgin;
  font-size: 36px; // Smallest mobile first size ( width: 320px )

  // All other devices ( until more breakpoints are added )
  @media screen and (min-width: 321px) {
    font-size: 40px;
  }
`;

// COLORS

export const COLORS = {
  CONTENT: "#fff",
  PURPLE: "#DE409F",
  BLACK: "#1A2027",
  BLUE: "#625CE4",
  TURQUOISE: "#5BECDE",
  LIGHT_BLUE: "#AFEEFF",
  DARK_PURPLE: "#89195C",
  LIGHT_PURPLE: "#CCCAFF",
  LIGHTER_PURPLE: "#E0DEFA",
  LIGHT_GREY: "#C0C0C0",
  MEDIUM_LIGHT_GREY: "#E0E0E0",
  LIGHTER_GREY: "#F1F1F1",
  SEMI_LIGHT_GREY: "#E0E0E0",
  DARK_GREY: "#7e7e7e",
  GREY: "#8E8E93",
  GREEN: "#69D895",
  BACKGROUND: "#fff",
  BUTTER_YELLOW: "#FDD47F",
  YELLOW_WARNING: "#FDD47F",
  RED_WARNING: "#FA614C",
  LIGHT_YELLOW: "#FFF6EE",
  LIGHT_PINK: "#F9ECF4",
  WHITE: "#fff",
  SUCCESS: "#1A2027",
  ERROR: "#625CE4",
  INFO: "#C43289",
  ALERT: "#FDD47F",
  PURPLE_30_OPACITY: "rgba(222, 64, 159, 0.3)",
  TRANSPARENT: "transparent",
};

export const STATUS_COLORS: IndexMap = {
  NOT_INVITED: COLORS.LIGHT_BLUE,
  ONBOARDING: COLORS.LIGHT_PINK,
  USER: COLORS.LIGHT_YELLOW,
  NEWBIE: COLORS.BUTTER_YELLOW,
  DELETED: COLORS.SEMI_LIGHT_GREY,
  LEFT_COMPANY: COLORS.SEMI_LIGHT_GREY,
  UNKNOWN: COLORS.LIGHTER_GREY,
};

export const MARGIN = {
  FORM_INPUT_MARGIN: "margin-bottom: 5px;",
};

export const LOGO_SMALL = css`
  width: 25px;
  height: 25px;
`;

export const LOGO_MEDIUM = css`
  width: 100px;
  height: 100px;
`;

export const LOGO_LARGE = css`
  width: 200px;
  height: 200px;
`;

export const TRANSITION_DURATION = css`
  transition-duration: 0.3s;
`;

export const BOX_SHADOW = css`
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 4px;
`;

export const BOX_SHADOW_HOVER = css`
  box-shadow: 0px 4px 84px rgba(0, 0, 0, 0.1);
`;

export const BORDER = css`
  border: 1px solid #f2f2f2;
`;

export const LOADING = css`
  border: 2px solid transparent;
  border-top: 2px solid white;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

// SPACING

export const XX_TINY_SPACING = "2px";
export const X_TINY_SPACING = "4px";
export const TINY_SPACING = "6px";

export const XXX_SMALL_SPACING = "8px";
export const XX_SMALL_SPACING = "12px";
export const X_SMALL_SPACING = "14px";

export const SMALL_SPACING = "16px";
export const MEDIUM_SPACING = "24px";
export const LARGE_SPACING = "32px";

export const X_LARGE_SPACING = "40px";
export const XX_LARGE_SPACING = "48px";
export const XXX_LARGE_SPACING = "56px";

export const SPACING = {
  XX_TINY: XX_TINY_SPACING,
  X_TINY: X_TINY_SPACING,
  TINY: TINY_SPACING,

  XXX_SMALL: XXX_SMALL_SPACING,
  XX_SMALL: XX_SMALL_SPACING,
  X_SMALL: X_SMALL_SPACING,

  SMALL: SMALL_SPACING,
  MEDIUM: MEDIUM_SPACING,
  LARGE: LARGE_SPACING,

  X_LARGE: X_LARGE_SPACING,
  XX_LARGE: XX_LARGE_SPACING,
  XXX_LARGE: XXX_LARGE_SPACING,
};
