import styled, { css } from "styled-components";

export type StyleProps = {
  horizontalAlignment?: boolean;
};

const HorizontalAlignmentStyling = css`
  display: flex;
  align-items: center;
`;

export const StyledWrapper = styled.div<StyleProps>`
  ${(props) => props.horizontalAlignment && HorizontalAlignmentStyling};
`;
