export const PUBLIC_ROUTE = {
  LANDING: "/",
  SIGN_IN: "/register/user",
  SIGN_UP: "/register",
  VERIFY_REGISTRATION_ADMIN: "/register/verify-admin",
  VERIFY_REGISTRATION_V2: "/register/v2/verify/:token/step1",
  VERIFY_REGISTRATION_V2_TRIAL: "/register/v2/verify/:token/trial",
  VERIFY_REGISTRATION_V2_STEP2: "/register/v2/verify/:token/step2",
  SUCCESS_REGISTRATION: "/register/success",
  TERMS_AND_CONDITIONS: "/termsandconditions",
  TINK_CALLBACK: "/tink/callback",
  SAVINGS_CALCULATOR: "/savings-calculator",
};

export const PRIVATE_ROUTE = {
  ORGANISATIONS: "/organisations",
  EMPLOYEES: "/:orgId/employees",
  INTEGRATIONS: "/:orgId/integrations",
  ORGANISATION_CHECKS: "/:orgId/tasks",
  SETTINGS: "/:orgId/settings",
  SUBSCRIPTIONS: "/:orgId/subscriptions",
  EXPORT: "/:orgId/export", // Remove once dashboard is complete
  DASHBOARD: "/:orgId/dashboard",
  SHIFT_REQUESTS: "/:orgId/shiftRequests",
  ZENERGY_CALLBACK: "/auth/zenegy/callback",
  DANLOEN_CALLBACK: "/auth/danloen/callback",
};
