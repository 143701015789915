import { SimpleOrganisationCard } from "Components/Combined/OrganisationCard";
import { LargeBody } from "Components/Core/Text";
import { COLORS } from "Shared/Constants/Style.constants";
import styled, { css } from "styled-components";

export const StyledListWrapper = styled.div<{ active: boolean }>`
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: flex-start;
  padding: 48px;
  border-radius: 0 0 8px 8px;

  -webkit-transform-origin: top;
  -webkit-transform: scaleY(0);
  -webkit-transition: all 0.2s ease-in-out;

  ${(props) => props.active && ACTIVE_LIST};
`;

export const StyledCard = styled(SimpleOrganisationCard)`
  margin: 27px;
`;

export const StyledList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const StyledText = styled(LargeBody)`
  color: ${COLORS.GREY};
`;

const ACTIVE_LIST = css`
  -webkit-transform: scaleY(1);
`;
