import React from "react";

import { IconProps } from "../Icon";

export const IconEyeOpen = (props: IconProps) => {
  return (
    <svg fill="none" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
      <clipPath id="a">
        <path d="M0 0h20v20H0z" />
      </clipPath>
      <g clipPath="url(#a)" fill={props.color}>
        <path d="M10 2.5C5.5303 2.5 1.5252 5.4328.0334 9.7981a.6274.6274 0 000 .4041c1.4918 4.3653 5.497 7.2981 9.9666 7.2981 4.4697 0 8.4749-2.9328 9.9666-7.2981a.627.627 0 000-.404C18.4749 5.4327 14.4697 2.5 10 2.5zm0 13.7502c-3.866 0-7.3408-2.5031-8.7123-6.2501 1.3715-3.747 4.8463-6.25 8.7123-6.25 3.866 0 7.3408 2.503 8.7123 6.25-1.3715 3.747-4.8463 6.2501-8.7123 6.2501z" />
        <path d="M10.0001 6.25c-2.068 0-3.7501 1.6822-3.7501 3.7501 0 2.0679 1.6822 3.75 3.7501 3.75 2.0679 0 3.75-1.6821 3.75-3.75 0-2.068-1.6821-3.7501-3.75-3.7501zm0 6.2501c-1.3789 0-2.5-1.1212-2.5-2.5 0-1.3789 1.1212-2.5 2.5-2.5s2.5 1.1212 2.5 2.5-1.1212 2.5-2.5 2.5z" />
      </g>
    </svg>
  );
};

IconEyeOpen.defaultProps = {
  color: "#979797",
};
