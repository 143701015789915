import React from "react";
import StyledLogo from "./Logo.styles";

type Props = any;

const Logo = (props: Props) => {
  return <StyledLogo {...props} />;
};

export default Logo;
