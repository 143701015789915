import { LOCALSTORAGE_KEY_USER_ID } from "Library/Firebase/Firebase.authentication";
import React, { ReactElement, useEffect, useState } from "react";

type Props = {
  children: ReactElement;
};

export const UserContext = React.createContext({
  userId: "",
  toggleUserId: (newUserId: string) => {},
});

export const UserProvider = ({ children }: Props) => {
  const [userId, setUserId] = useState<string>(
    localStorage.getItem(LOCALSTORAGE_KEY_USER_ID) ?? ""
  );

  useEffect(() => {
    if (userId) {
      localStorage.setItem(LOCALSTORAGE_KEY_USER_ID, userId);
    }
  }, [userId]);

  return (
    <UserContext.Provider
      value={{ userId, toggleUserId: (userId) => setUserId(userId) }}
    >
      {children}
    </UserContext.Provider>
  );
};
