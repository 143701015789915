import { COLORS } from "Shared/Constants/Style.constants";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

  html,body {
    font-family: WorkSans;
    background-color: ${COLORS.BACKGROUND};
    padding: 0px !important;
    margin: 0px !important;
    height: 100%;
  }

  h1{
      font-family: BalginLightSmExpanded;
      color: ${COLORS.BLACK};
      font-weight: 600;
      font-size: 28px;
  }

  h2{
    color: ${COLORS.DARK_GREY};
    font-weight: 500;
  }

  h3{
    color: ${COLORS.DARK_GREY};
    font-weight: 400;
  }

  p{
    color: ${COLORS.DARK_GREY};
  }

  a{
    color: ${COLORS.PURPLE};
    :hover{
      color: ${COLORS.DARK_PURPLE};
    }
  }


`;

export default GlobalStyle;
