import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import firebaseConfig from "./Firebase.config";

export const firebaseApp = !firebase.apps.length
  ? firebase.initializeApp(firebaseConfig)
  : firebase.app();
export const auth = () => firebase.auth();
export const storageRef = () => firebase.storage().ref();

export default firebase;
