import { AvatarContainer } from "Components/ContainersV2/AvatarContainer/AvatarContainer";
import { SelectOrganisationContainer } from "Components/ContainersV2/SelectOrganisationContainer";
import Logo from "Components/CoreV2/Logo";
import SiteConfig from "Config/Site.config";
import { signout } from "Library/Firebase/Firebase.authentication";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";
import { COLORS } from "Shared/Constants/Style.constants";

import {
  StyledDivider,
  StyledEmptySpaceLeftOfMenu,
  StyledHeaderWrapper,
  StyledIconMenu,
  StyledIconMenuItem,
  StyledIconMobileMenuItem,
  StyledLargeBodyText,
  StyledMenuCloseIcon,
  StyledMenuWrapper,
  StyledMobileLogOut,
  StyledMobileMenu,
  StyledMobileMenuItem,
  StyledMobileMenuWrapper,
  StyledOuterWrapper,
  StyledRegularBodyText,
  StyledTextMenu,
  StyledTextMenuItem,
} from "./Navigation.styles";
import {
  headerIconOptions,
  headerTextOptions,
  NavigationOptionKeys,
} from "./options";

type MobileNavigationProps = {
  toggleMobileMenu: () => void;
  orgName?: string;
  orgId: string;
  authAccessData: NavigationAuthAccessData;
};

const filterAuth = (
  option: NavigationOption,
  authAccessData: NavigationAuthAccessData
) => {
  switch (option.key) {
    case NavigationOptionKeys.DASHBOARD:
      return authAccessData.canReadDashboard;
    case NavigationOptionKeys.EMPLOYEES:
      return authAccessData.canReadEmployees;
    case NavigationOptionKeys.INTEGRATIONS:
      return authAccessData.canReadIntegrations;
    case NavigationOptionKeys.TASKS:
      return authAccessData.canReadTaskList;
    case NavigationOptionKeys.SETTINGS:
      return authAccessData.canReadOrgSettings;
    case NavigationOptionKeys.SHIFT_REQUESTS:
      return authAccessData.canReadShiftRequests;
    default:
      return true;
  }
};

export const MobileNavigation = (props: MobileNavigationProps) => {
  const history = useHistory();
  const path = history.location.pathname;
  //TODO not the best approach to get parameter from URL
  const currentPath = path.split("/")[2];
  const { t } = useTranslation(["menu"]);

  const logOut = async () => {
    await signout();
    localStorage.removeItem("token");
  };

  const textMenuOptions = headerTextOptions.filter((option) =>
    filterAuth(option, props.authAccessData)
  );
  const iconMenuOptions = headerIconOptions.filter((option) =>
    filterAuth(option, props.authAccessData)
  );

  return (
    <StyledMobileMenuWrapper>
      <StyledEmptySpaceLeftOfMenu onClick={() => props.toggleMobileMenu()} />
      <StyledOuterWrapper>
        <StyledHeaderWrapper>
          <StyledMenuCloseIcon onClick={() => props.toggleMobileMenu()} />
          <NavLink to={"/organisations"}>
            <Logo src={SiteConfig.siteLogo} width={"140px"} />
          </NavLink>
        </StyledHeaderWrapper>
        <StyledMobileMenu mode="inline" selectedKeys={[currentPath]}>
          <SelectOrganisationContainer
            activeCompanyName={props?.orgName ?? ""}
          />
          <StyledDivider horizontal />

          {textMenuOptions.length > 0 && (
            <>
              {textMenuOptions.map((option) => {
                return (
                  <StyledMobileMenuItem key={option.key.toString()}>
                    <NavLink to={option.key.toString()}>
                      <StyledLargeBodyText>
                        {t(option.label)}
                      </StyledLargeBodyText>
                    </NavLink>
                  </StyledMobileMenuItem>
                );
              })}

              <StyledDivider horizontal />
            </>
          )}
          {iconMenuOptions.length > 0 && (
            <>
              {iconMenuOptions.map((option) => {
                return (
                  <StyledIconMobileMenuItem
                    key={option.key.toString()}
                    icon={option.leftIcon}
                  >
                    <NavLink to={option.key.toString()}>
                      <StyledRegularBodyText>
                        {t(option.label)}
                      </StyledRegularBodyText>
                    </NavLink>
                  </StyledIconMobileMenuItem>
                );
              })}
              <StyledDivider horizontal />
            </>
          )}
          <AvatarContainer
            backgroundColor={COLORS.PURPLE_30_OPACITY}
            labelColor={COLORS.PURPLE}
            showDetails={true}
            orgId={props.orgId}
          />
          <StyledMobileLogOut>
            <NavLink to="" onClick={() => logOut()}>
              Log out
            </NavLink>
          </StyledMobileLogOut>
        </StyledMobileMenu>
      </StyledOuterWrapper>
    </StyledMobileMenuWrapper>
  );
};

export type NavigationAuthAccessData = {
  canReadDashboard: boolean;
  canReadEmployees: boolean;
  canReadIntegrations: boolean;
  canReadTaskList: boolean;
  canReadOrgSettings: boolean;
  canReadShiftRequests: boolean;
};

export type NavigationOption = {
  key: string;
  label: string;
  leftIcon?: JSX.Element;
};

type Props = {
  authAccessData: NavigationAuthAccessData;
};

const Navigation = ({ authAccessData }: Props) => {
  const history = useHistory();
  const path = history.location.pathname;
  //TODO not the best approach to get parameter from URL
  const currentPath = path.split("/")[2];
  const { t } = useTranslation(["menu"]);

  const textMenuOptions = headerTextOptions.filter((option) =>
    filterAuth(option, authAccessData)
  );
  const iconMenuOptions = headerIconOptions.filter((option) =>
    filterAuth(option, authAccessData)
  );

  return (
    <StyledMenuWrapper>
      {textMenuOptions.length > 0 && (
        <StyledTextMenu selectedKeys={[currentPath]}>
          {textMenuOptions.map((option) => {
            return (
              <StyledTextMenuItem key={option.key.toString()}>
                <NavLink to={option.key.toString()}>{t(option.label)}</NavLink>
              </StyledTextMenuItem>
            );
          })}
        </StyledTextMenu>
      )}
      {iconMenuOptions.length > 0 && (
        <StyledIconMenu selectedKeys={[currentPath]}>
          {iconMenuOptions
            .filter((iconMenuOption) =>
              filterAuth(iconMenuOption, authAccessData)
            )
            .map((option) => {
              return (
                <StyledIconMenuItem
                  key={option.key.toString()}
                  icon={option.leftIcon}
                >
                  <NavLink to={option.key.toString()}>
                    {t(option.label)}
                  </NavLink>
                </StyledIconMenuItem>
              );
            })}
        </StyledIconMenu>
      )}
    </StyledMenuWrapper>
  );
};

export default Navigation;
