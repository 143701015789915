import { List } from "antd";
import { BlackDownArrow } from "Components/CoreV2/Icon";
import { RegularBodyText } from "Components/CoreV2/Text";
import { Wrapper } from "Components/CoreV2/Wrapper";
import {
  SMALL_SPACING,
  TINY_SPACING,
  XX_SMALL_SPACING
} from "Shared/Constants/Style.constants";
import styled from "styled-components";

export const StyledWrapper = styled.div<{ active: boolean }>`
  position: ${(props) => (props.active ? "absolute" : "static")};
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  background: white;
`;

export const StyledRegularBodyText = styled(RegularBodyText)`
  font-size: 18px;
`;

export const StyledBlackDownArrow = styled(BlackDownArrow)<{ active: boolean }>`
  height: 12px;
  width: 12px;
  margin-left: ${XX_SMALL_SPACING};
  cursor: pointer;

  ${(props) => props.active && "transform: rotate(180deg);"};
`;

export const StyledList = styled(List)`
  width: 100%;
  margin-top: ${SMALL_SPACING};

  max-width: 300px;
  min-width: 200px;

  .ant-row {
    margin: 0 !important;

    & > div {
      flex: 0 0 50%;
    }
  }

  .ant-col {
    padding: 0 !important;
  }
`;

export const StyledListWrapper = styled(Wrapper)`
  margin-top: ${TINY_SPACING};
`;
