import { Avatar } from "Components/CoreV2/Avatar";
import { RegularBodyText, SmallBodyText } from "Components/CoreV2/Text";
import { Wrapper } from "Components/CoreV2/Wrapper";
import { UserContext } from "Context/UserProvider";
import React, { useContext } from "react";
import { COLORS } from "Shared/Constants/Style.constants";

import * as Sentry from "@sentry/react";

import { useGetUserInfoQuery } from "../../../generated/graphql";
import { StyledDetails } from "./AvatarContainer.styles";

type Props = {
  backgroundColor?: string;
  labelColor?: string;
  showDetails?: boolean;
  orgId?: string;
};

export const AvatarContainer = (props: Props) => {
  const { userId } = useContext(UserContext);
  const { data, loading, error } = useGetUserInfoQuery({
    variables: { id: userId },
    skip: userId ? false : true,
  });

  if (loading) return <div>loading...</div>;
  if (error) {
    Sentry.captureException(error);
    return <div>error...</div>;
  }

  const firstName = data?.user.firstName ?? "?";
  const lastName = data?.user.lastName ?? "?";
  const avatarInitial = firstName[0];

  const fullName = `${firstName} ${lastName}`;
  const userRoleForOrganisation = data?.user?.roles?.find(
    (role) => role?.organisation?._id === props.orgId
  );

  const userRoleName =
    userRoleForOrganisation?.type.toLowerCase() ?? "Unknown role";
  const formatUserRoleName =
    userRoleName[0].toUpperCase() + userRoleName.slice(1);

  return props?.showDetails ? (
    <Wrapper horizontalAlignment>
      <Avatar
        initial={avatarInitial}
        backgroundColor={props.backgroundColor}
        labelColor={props.labelColor}
      />
      <StyledDetails>
        <RegularBodyText>{fullName}</RegularBodyText>
        <SmallBodyText color={COLORS.DARK_GREY}>
          {formatUserRoleName}
        </SmallBodyText>
      </StyledDetails>
    </Wrapper>
  ) : (
    <Avatar
      initial={avatarInitial}
      backgroundColor={props.backgroundColor}
      labelColor={props.labelColor}
    />
  );
};
