import { ReactComponent as IntegrationSelection1 } from "Assets/Icons/1-1-integration.svg";
import { ReactComponent as IntegrationSelection2 } from "Assets/Icons/1-many-integration.svg";
import { ReactComponent as AddIcon } from "Assets/Icons/addIcon.svg";
import Cross from "Assets/Icons/cross.svg";
import { ReactComponent as DownloadWhite } from "Assets/Icons/download-white.svg";
import { ReactComponent as Download } from "Assets/Icons/download.svg";
import { ReactComponent as Export } from "Assets/Icons/export.svg";
import { ReactComponent as Help } from "Assets/Icons/help.svg";
import { ReactComponent as Integration } from "Assets/Icons/integration.svg";
import { ReactComponent as IntegrationLink } from "Assets/Icons/integration_link.svg";
import { ReactComponent as IntegrationSelection3 } from "Assets/Icons/many-many-integration.svg";
import Pencil from "Assets/Icons/pencil.svg";
import { ReactComponent as Retry } from "Assets/Icons/retry.svg";
import { ReactComponent as Settings } from "Assets/Icons/settings.svg";
import { ReactComponent as SetupIntegration } from "Assets/Icons/setupIntegration.svg";
import { ReactComponent as TaskList } from "Assets/Icons/task list.svg";
import { COLORS, TRANSITION_DURATION } from "Shared/Constants/Style.constants";
import styled, { css } from "styled-components";

import { PlusCircleFill } from "@styled-icons/bootstrap/PlusCircleFill";
import { RightArrowAlt } from "@styled-icons/boxicons-regular/RightArrowAlt";

const ICON = css`
  cursor: pointer;
  :hover {
    filter: brightness(150%);
  }
  :active {
    color: ${COLORS.DARK_PURPLE};
    border-color: ${COLORS.DARK_PURPLE};
  }
  ${TRANSITION_DURATION}
`;

export const StyledPlusIcon = styled(PlusCircleFill)`
  color: ${COLORS.BLUE};
  width: 30px;
  height: 30px;
  margin: 10px;
  ${ICON};
`;

export const StyledDeleteIcon = styled.div`
  background: ${COLORS.LIGHTER_GREY};
  background-image: url(${Cross});
  background-repeat: no-repeat;
  background-position: center bottom;
  border-radius: 15px;
  width: 30px;
  height: 30px;
  color: ${COLORS.DARK_GREY};
  display: inline-block;
  vertical-align: middle;
  ${ICON};
`;

export const StyledEdit = styled.div`
  background: ${COLORS.LIGHTER_GREY};
  background-image: url(${Pencil});
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 15px;
  width: 30px;
  height: 30px;
  color: ${COLORS.DARK_GREY};
  display: inline-block;
  vertical-align: middle;
  margin-right: 12px;
  ${ICON};
`;

export const StyledHelpIcon = styled(Help)`
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  fill: ${COLORS.BLACK};
  transition: fill 0.3s;
`;

export const StyledArrowIcon = styled(RightArrowAlt)`
  color: ${COLORS.WHITE};
  width: 20px;
  height: 20px;
  margin: 10px;
  ${ICON};
`;

export const StyledIntegrationSelection1 = styled(IntegrationSelection1)`
  ${ICON};
`;

export const StyledIntegrationSelection2 = styled(IntegrationSelection2)`
  ${ICON};
`;

export const StyledIntegrationSelection3 = styled(IntegrationSelection3)`
  ${ICON};
`;

export const StyledIntegrationLink = styled(IntegrationLink)`
  ${ICON};
`;
export const StyledIntegration = styled(Integration)`
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  fill: ${COLORS.BLACK};
  transition: fill 0.3s;
  ${ICON};
`;

export const StyledSetupIntegration = styled(SetupIntegration)`
  display: inline-block;
  vertical-align: middle;
  fill: ${COLORS.BLACK};
  transition: fill 0.3s;
  ${ICON};
`;

export const StyledAddIcon = styled(AddIcon)`
  ${ICON};
`;

export const StyledSettings = styled(Settings)`
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  fill: ${COLORS.BLACK};
  transition: fill 0.3s;
`;

export const StyledExport = styled(Export)`
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  fill: ${COLORS.BLACK};
  transition: fill 0.3s;
`;

export const StyledTaskList = styled(TaskList)`
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  fill: ${COLORS.BLACK};
  transition: fill 0.3s;
`;

export const StyledDownload = styled(Download)`
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
`;

export const StyledRetry = styled(Retry)`
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
`;

export const StyledDownloadWhite = styled(DownloadWhite)`
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
`;
