import React, { ReactNode } from "react";

import { StyledWrapper, StyleProps } from "./Wrapper.styles";

type Props = {
  children?: ReactNode;
  className?: string;
  onClick?: () => void;
} & StyleProps;

export const Wrapper = (props: Props) => {
  return <StyledWrapper {...props}>{props.children}</StyledWrapper>;
};
