import styled from "styled-components";

/**
 * Right-positioned stickers
 */
const TOP_RIGHT = (positionVariance: number) => {
  const rightVariance = (Math.random() - 0.5) * positionVariance;
  const right = 15 - rightVariance;
  const topVariance = (Math.random() - 0.5) * positionVariance;
  const top = 20 + topVariance;

  return `
    position: absolute;
    right: ${right}%;
    top: ${top}%;
    @media only screen and (max-width: 600px) {
      right: 3%;
      top: 5%;
    }
  `;
};

const BOTTOM_RIGHT = (positionVariance: number) => {
  const rightVariance = (Math.random() - 0.5) * positionVariance;
  const right = 15 - rightVariance;
  const topVariance = (Math.random() - 0.5) * positionVariance;
  const top = 80 + topVariance;

  return `
    position: absolute;
    right: ${right}%;
    top: ${top}%;
    @media only screen and (max-width: 600px) {
      right: 3%;
      top: 87%;
    }
  `;
};

const MID_RIGHT = (positionVariance: number) => {
  const rightVariance = (Math.random() - 0.5) * positionVariance;
  const right = 5 - rightVariance;
  const topVariance = (Math.random() - 0.5) * positionVariance;
  const top = 60 + topVariance;

  return `
    position: absolute;
    right: ${right}%;
    top: ${top}%;
    @media only screen and (max-width: 1000px) {
      display: none;
    }
  `;
};

/**
 * Left-positioned stickers
 */
const MID_LEFT = (positionVariance: number) => {
  const rightVariance = (Math.random() - 0.5) * positionVariance;
  const right = 90 + rightVariance;
  const topVariance = (Math.random() - 0.5) * positionVariance;
  const top = 50 + topVariance;

  return `
    position: absolute;
    right: ${right}%;
    top: ${top}%;
    @media only screen and (max-width: 1000px) {
      display: none;
    }
  `;
};

const TOP_LEFT = (positionVariance: number) => {
  const rightVariance = (Math.random() - 0.5) * positionVariance;
  const right = 80 + rightVariance;
  const topVariance = (Math.random() - 0.5) * positionVariance;
  const top = 25 + topVariance;

  return `
    position: absolute;
    right: ${right}%;
    top: ${top}%;
    @media only screen and (max-width: 600px) {
      right: 93%;
      top: 7%;
    }
  `;
};

const BOTTOM_LEFT = (positionVariance: number) => {
  const variance = (Math.random() - 0.5) * positionVariance;
  const right = 80 + variance;

  return `
  position: absolute;
  right: ${right}%;
  top: 80%;
  @media only screen and (max-width: 600px) {
    right: 93%;
    top: 87%;
  }
`;
};

const POSITIONS = [
  [TOP_LEFT, BOTTOM_RIGHT, MID_LEFT],
  [TOP_RIGHT, BOTTOM_LEFT, MID_RIGHT],
  [BOTTOM_LEFT, MID_RIGHT, MID_LEFT],
  [MID_LEFT, TOP_RIGHT, BOTTOM_RIGHT],
];
let currentPosition = Math.floor(Math.random() * POSITIONS.length);

const getNextPosition = (props: any): string => {
  currentPosition++;

  const seed = props?.seed ?? Math.floor(Math.random() * 100);
  const randomPositionSet = POSITIONS[seed % POSITIONS.length];
  const randomPosition =
    randomPositionSet[currentPosition % randomPositionSet.length];

  const positionVariance = 10;
  return randomPosition(positionVariance);
};

export const StyledRandomSticker = styled.img`
  ${(props) => getNextPosition(props)}
`;
