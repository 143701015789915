import { LogoutForm } from "Components/Combined/LogoutForm";
import { UserContext } from "Context/UserProvider";
import {
  DEPRECATED_LOCALSTORAGE_KEY_USER_ACCESS_ABILITIES,
  LOCALSTORAGE_KEY_USER_ACCESS_ABILITIES,
  signout
} from "Library/Firebase/Firebase.authentication";
import React, { useContext } from "react";

import * as Sentry from "@sentry/react";

import { useGetUserInfoQuery } from "../../../generated/graphql";

export const Logout = () => {
  const { userId } = useContext(UserContext);
  const { data, loading, error } = useGetUserInfoQuery({
    variables: { id: userId },
    skip: userId ? false : true,
  });

  if (loading) return <div>loading...</div>;
  if (error) {
    Sentry.captureException(error);
    return <div>error...</div>;
  }

  const user = data?.user;

  const logout = async () => {
    await signout();
    localStorage.removeItem("token");
    localStorage.removeItem(LOCALSTORAGE_KEY_USER_ACCESS_ABILITIES);
    localStorage.removeItem(DEPRECATED_LOCALSTORAGE_KEY_USER_ACCESS_ABILITIES);
  };

  return (
    <React.Fragment>
      <LogoutForm name={user?.firstName ?? ""} logout={logout} />
    </React.Fragment>
  );
};
